
import * as Constants from './dmchitieuConstant';

export function setData(dmchitieuList) {
    return {
        type: Constants.DMCHITIEU_SET_LIST_DATA,
        dmchitieuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHITIEU_SET_LIST_META,
        meta
    };
}