import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { LoadingComponent } from 'shared/components';
import { Context } from '../TKTaiKhoanService';


const schema = object({
    title: string().trim().required('Bạn chưa nhập họ và tên').min(3, 'Bạn nhập tối thiểu 3 ký tự').max(100, 'Bạn nhập tối đa 100 ký tự'),
    tendangnhap: string().trim().required('Bạn chưa nhập tên đăng nhập').min(5, 'Bạn nhập tối thiểu 5 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự'),
    dienthoai: string().trim().required('Bạn chưa nhập số điện thoại').max(30, 'Bạn nhập tối đa 30 ký tự'),
    email: string().email('Email không đúng định dạng').nullable().min(3, 'Bạn nhập tối thiểu 3 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự'),
});

const userDefault = {
    title: '',
    tendangnhap: '',
    dienthoai: '',
    email: '',

};
class TaiKhoanFormComponent extends Component {
    static propTypes = {
        onClose: PropTypes.func
    };
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            data: userDefault,
            listGroup: [],
            editMode: false,
            loading: false,
            isShow: false,
            action: 'new',
            id: null,

        };
        this.subscriptions = {};

        //this.url = props ? props.url : '';
        //this.http = http;
    }

    componentDidMount() {
        this.subscriptions['sendToFormAction'] = this.context.tktaikhoanService.sendToFormAction.subscribe(res => {
            switch (res.action) {

                case 'edit':
                    this.setState({ editMode: true, isShow: res.isShow, action: res.action, id: null, loading: true });
                    this.context.tktaikhoanService.getByUsername(res.username).subscribe(res => {
                        this.setState({
                            data: res,
                            id: res.id,
                            loading: false
                        });
                    });
                    break;
                case 'read':
                    this.setState({ editMode: false, isShow: res.isShow, action: res.action, id: null, loading: true });
                    this.context.tktaikhoanService.getByUsername(res.username).subscribe(res => {
                        this.setState({
                            data: res,
                            id: res.id,
                            loading: false
                        });
                    });
                    break;
                default:
                    this.setState({ editMode: true, isShow: res.isShow, action: 'read', id: null, data: userDefault });
                    break;
            }
        });

    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    onSubmit(data) {
        this.setState({
            loading: true
        });

        if (this.state.id) {
            this.subscriptions['update'] = this.context.tktaikhoanService.update(data, this.state.id).subscribe(() => {
                this.context.beh.toast('Cập nhật thành công');
                this.handleClose(true);
                this.setState({
                    loading: false
                });
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error.error);
                });

        }
    }
    onEdit() {
        this.setState({
            editMode: true
        });
    }
    handleClose(isRefesh) {
        this.setState({ isShow: false, data: userDefault });
        if (this.props.onClose) {
            this.props.onClose(isRefesh);
        }

    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    computedTitle() {

        if (this.state.editMode) {
            return 'Chỉnh sửa thông tin tài khoản';
        }
        return 'Chi tiết tài khoản';

    }
    render() {
        return (
            <Modal size="lg" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.handleClose.bind(this)}>
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => {
                        this.onSubmit(values);
                    }}
                    enableReinitialize={true}
                    initialValues={this.state.data}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                            <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress.bind(this)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.computedTitle()}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="title">
                                            <Form.Label>Họ và tên</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="title"
                                                        value={values.title || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.title && !!errors.title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.title}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.title}</p>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="tendangnhap">
                                            <Form.Label>Tên đăng nhập</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="tendangnhap"
                                                        readOnly={this.state.id !== null}
                                                        value={values.tendangnhap || ''}
                                                        onChange={(ev) => { handleChange(ev); handleBlur(ev); }}
                                                        isInvalid={touched.tendangnhap && !!errors.tendangnhap}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.tendangnhap}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.tendangnhap}</p>
                                            }
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="dienthoai">
                                            <Form.Label>Điện thoại</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="dienthoai"
                                                        value={values.dienthoai || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.dienthoai && !!errors.dienthoai}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.dienthoai}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.dienthoai}</p>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        value={values.email || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.email && !!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </React.Fragment>
                                                : <p className="form-control-static">{values.email}</p>
                                            }
                                        </Form.Group>
                                    </Form.Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    
                                    {this.state.editMode ?
                                        <Button type="submit">Lưu lại</Button>
                                        :
                                        <React.Fragment>
                                            <Button variant="primary" className="ml-2" type="button" onClick={this.onEdit.bind(this)}>Sửa</Button>
                                        </React.Fragment>
                                    }
                                    <Button variant="default" onClick={() => { this.handleClose(false); }}>
                                        Đóng
                                </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                </Formik>
            </Modal>

        );
    }
}
export { TaiKhoanFormComponent };