
import * as Constants from './tknhomnguoidungConstant';

export function setData(tknhomnguoidungList) {
    return {
        type: Constants.TKNHOMNGUOIDUNG_SET_LIST_DATA,
        tknhomnguoidungList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TKNHOMNGUOIDUNG_SET_LIST_META,
        meta
    };
}