
import * as Constants from './dmlinhvucthuonghieuConstant';

export function setData(dmlinhvucthuonghieuList) {
    return {
        type: Constants.DMLINHVUCTHUONGHIEU_SET_LIST_DATA,
        dmlinhvucthuonghieuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMLINHVUCTHUONGHIEU_SET_LIST_META,
        meta
    };
}