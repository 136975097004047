
import * as Constants from './dmlinhvuckinhdoanhConstant';

export function setData(dmlinhvuckinhdoanhList) {
    return {
        type: Constants.DMLINHVUCKINHDOANH_SET_LIST_DATA,
        dmlinhvuckinhdoanhList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMLINHVUCKINHDOANH_SET_LIST_META,
        meta
    };
}