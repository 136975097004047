
import * as Constants from './dmloaisanphamConstant';

export function setData(dmloaisanphamList) {
    return {
        type: Constants.DMLOAISANPHAM_SET_LIST_DATA,
        dmloaisanphamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMLOAISANPHAM_SET_LIST_META,
        meta
    };
}