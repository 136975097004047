import * as Constant from './dmloaivanbanphapluatConstant';

const dmloaivanbanphapluat = {
    dmloaivanbanphapluatList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '' ,       
    }
};
const dmloaivanbanphapluatReducer = (state = dmloaivanbanphapluat, action) => {
    switch (action.type) {
        case Constant.DMLOAIVANBANPHAPLUAT_SET_LIST_DATA:
        return Object.assign({}, state, { dmloaivanbanphapluatList: action.dmloaivanbanphapluatList });
        case Constant.DMLOAIVANBANPHAPLUAT_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmloaivanbanphapluatReducer};