
import * as Constants from './traloiphieukhaosatConstant';

export function setData(traloiphieukhaosatList) {
    return {
        type: Constants.TRALOIPHIEUKHAOSAT_SET_LIST_DATA,
        traloiphieukhaosatList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TRALOIPHIEUKHAOSAT_SET_LIST_META,
        meta
    };
}