
import * as Constants from './dmlinhvucsukienConstant';

export function setData(dmlinhvucsukienList) {
    return {
        type: Constants.DMLINHVUCSUKIEN_SET_LIST_DATA,
        dmlinhvucsukienList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMLINHVUCSUKIEN_SET_LIST_META,
        meta
    };
}