import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { LoadingComponent, GridView } from 'shared/components';
import { TKTaiKhoanAction } from '../../../redux/actions';
import { Context } from '../TKTaiKhoanService';

class TKTaiKhoanNhomNDFormComponent extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        history: PropTypes.object,
        location: PropTypes.object,
        setData: PropTypes.func,
        setMeta: PropTypes.func,
        data: PropTypes.array,
        meta: PropTypes.object
    };
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            listGroup: [],
            editMode: false,
            loading: false,
            isShow: false,
            action: 'new',
            id: null,
            tenNhom: "",
            idNhom: null,
            
        };
        this.subscriptions = {};
    }
    
    componentDidMount() {
        this.subscriptions['sendToForm'] = this.context.tktaikhoanService.sendToForm.subscribe(res => {
            this.setState({
                editMode: true,
                isShow: res.isShow,
                action: 'new',
                id: null,
                data: res,
                idNhom: res.idNhom,
                tenNhom: res.tenNhom,
                maNhom: res.maNhom
            });
             
            this.fetchData(this.props.meta);
        });
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }

    fetchData(meta) {
       
        this.setState({ loading: true });
        this.subscriptions['getmany'] = this.context.tktaikhoanService.getMany(meta).subscribe(res => {            
            this.props.setData(res.data || []);
            // console.log(this.props);
            this.props.setMeta({
                page: res.currentPage,
                page_size: res.pageSize,
                total: res.totalRows
            });
            if(this.props.data.length){
                for (let i = 0; i < this.props.data.length; i++) {
                    this.context.tktaikhoanService.getNguoiDungNhomND(this.state.idNhom,this.props.data[i].id).subscribe(res => {
                        if (res.data.length ) {
                            //this.updateNguoiDungNhomND(res.data[0].iD_TAIKHOAN,false);
                            document.getElementById(`check-box-idtaikhoan-${res.data[0].iD_TAIKHOAN}`).checked = true; 
                            console.log("loaded");
                        }
                    }); 
                }
                
            }
            
            this.setState({ loading: false });
        }, err => {
            console.log(err);
            this.setState({ loading: false });
        });
    }
    updateNguoiDungNhomND(idtaikhoan, isFire) { 
      
        this.setState({ loading: true });
      
        // document.getElementById('check-box-tknhomnd-30').setAttribute("data-fire",true);
        if (isFire) {
            var isCheck = document.getElementById(`check-box-idtaikhoan-${idtaikhoan}`).checked;
            if (isCheck === true) {
                // console.log("add");
                this.context.tktaikhoanService.getNguoiDungNhomND(this.state.idNhom,idtaikhoan).subscribe(res => { 
                    if (!res.data.length) { 
                        this.context.tktaikhoanService.addNguoiDungNhomND(this.state.idNhom,this.state.maNhom,idtaikhoan).subscribe(() => {
                            this.setState({
                                loading: false
                            });
                            
                        });
                    }
                });
            } else {
                // console.log("remove");
                this.context.tktaikhoanService.getNguoiDungNhomND(this.state.idNhom,idtaikhoan).subscribe(res => { 
                    if (res.data.length) {
                        this.context.tktaikhoanService.delNguoiDungNhomND(res.data[0].id).subscribe(() => {
                            this.setState({
                                loading: false
                            });
                        });
                    }
                }); 
            }
           // setTimeout(this.fetchData(this.props.meta), 3000);
            
        }
        
    } 
    onSubmit(data) {
        this.setState({
            loading: true
        });
        if (this.state.id) {
            this.subscriptions['update'] = this.context.tktaikhoanService.update(data, this.state.id).subscribe(() => {
                this.context.beh.toast('Cập nhật thành công');
                this.handleClose(true);
                this.setState({
                    loading: false
                });
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error.error);
                });

        } else {            
            this.subscriptions['create'] = this.context.tktaikhoanService.create(data).subscribe(() => {
                this.context.beh.toast('Thêm mới thành công');
                this.handleClose(true);
                this.setState({
                    loading: false
                });
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error.error);
                });

        }

    }
    onEdit() {
        this.setState({
            editMode: true
        });
    }
    handleClose(isRefesh) {
        this.setState({ isShow: false });
        if (this.props.onClose) {
            this.props.onClose(isRefesh);
        }

    }     
    
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    computedTitle() {
        if (this.state.id) {
            if (this.state.editMode) {
                return `Cập nhật người dùng cho nhóm ${this.state.tenNhom}`;
            }
            return `Chi tiết người dùng trong nhóm ${this.state.tenNhom}`;
        }
        return `Cập nhật người dùng cho nhóm ${this.state.tenNhom}`;
    }
    handleChange(res){
        let newMeta={};
        switch (res.event) {
        case 'changeSelected':                
            break;   
        case 'changePageSize':
            newMeta = Object.assign({}, this.props.meta, res.data);
            if (this.props.meta.page_size !== newMeta.page_size) {
                // this.props.setMeta({ page_size: newMeta.page_size });
                this.fetchData(newMeta);
            }
            break;
        case 'changePage':
            newMeta = Object.assign({}, this.props.meta, res.data);
            if (this.props.meta.page !== newMeta.page) {
                // this.props.setMeta({ page: newMeta.page });
                this.fetchData(newMeta);
            }
            break;
        case 'changeSort':
            newMeta = Object.assign({}, this.props.meta, res.data);
            if (this.props.meta.sort !== newMeta.sort) {
                this.props.setMeta({ sort: newMeta.sort });
                this.fetchData(newMeta);
            }
            break;
        case 'changeKeySearch':
            newMeta = Object.assign({}, this.props.meta, res.data);
            this.props.setMeta({ search: newMeta.search });
            this.fetchData(newMeta);
        
            break;
        case 'changeFilter':
            newMeta = Object.assign({}, this.props.meta, res.data);
            this.props.setMeta({ filter: newMeta.filter });
            this.fetchData(newMeta);
        
            break;
        default:
            break;
        }
    }
    render() {
        return (
            <Modal size="lg" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.handleClose.bind(this)}>
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <Formik
                    onSubmit={(values) => {
                        this.onSubmit(values);
                    }}
                    enableReinitialize={true}
                    initialValues={this.state.data}
                >
                    {({
                        handleSubmit,
                    }) => (
                            <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress.bind(this)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.computedTitle()}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <GridView
                                        loading={this.state.loading}
                                        handleChange={this.handleChange.bind(this)}
                                    >            
                                    <GridView.Header
                                        keySearch={this.props.meta.search}
                                       
                                    ></GridView.Header>                            
                                        <GridView.Table
                                            className="col-12"
                                            noSelected={true}
                                            data={this.props.data}
                                            keyExtractor={({ item }) => {
                                                return item.id;
                                            }}
                                            sort={this.props.meta.sort}
                                            page={this.props.meta.page}
                                            page_size={this.props.meta.page_size}
                                            total={this.props.meta.total}
                                        >
                                            <GridView.Table.Column style={{ width: '20px' }}
                                                title="STT"
                                                className="text-center"
                                                body={({ index }) => (
                                                    <span>{index + 1 + (this.props.meta.page - 1) * this.props.meta.page_size}</span>
                                                )} />
                                            <GridView.Table.Column style={{}}
                                                title="Họ và tên"
                                                sortKey="title"
                                                body={({ item }) => (
                                                    <span>{item.title}</span>
                                                )} />
                                            <GridView.Table.Column style={{}}
                                                title="Tên đăng nhập"
                                                sortKey="tendangnhap"
                                                body={({ item }) => (
                                                    <span>{item.tendangnhap}</span>
                                                )} />

                                            <GridView.Table.Column
                                                title="Điện thoại"
                                                sortKey="dienthoai"
                                                body={({ item }) => (
                                                    <span>{item.dienthoai}</span>
                                                )} />
                                            <GridView.Table.Column
                                                title="Ngày đăng ký"
                                                sortKey="ngaytao"
                                                body={({ item }) => (
                                                    <span>{this.context.tktaikhoanService.formatDateTime(item.ngaytao)}
                                                        </span>
                                                )} />
                                            <GridView.Table.Column
                                                title="Trạng thái"
                                                sortKey="trangthai"
                                                body={({ item }) => (
                                                    <span>{item.trangthai === 1 ? 'Tạo mới' : item.trangthai === 2 ? 'Hoạt động' : item.trangthai === 3 ? 'Bị khóa' : ''}</span>
                                                )} />
                                            <GridView.Table.Column style={{ width: '30px' }} className="view-action"
                                                title="#"
                                                className="text-center"
                                                body={({ item }) => (
                                                    <Form.Group>
                                                        <Form.Check type="checkbox" 
                                                        onChange={() => { this.updateNguoiDungNhomND(item.id, true); }} 
                                                        // data-fire={false}
                                                        label="" id={`check-box-idtaikhoan-${item.id}`}/>
                                                    </Form.Group>                                                    
                                                )} />
                                        </GridView.Table>
                                    </GridView>
                                </Modal.Body>
                                <Modal.Footer >
                                    <Button variant="default" onClick={() => { this.handleClose(false); }}>
                                        Đóng
                                </Button>                                    
                                </Modal.Footer>
                            </Form>
                        )}
                </Formik>
            </Modal>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        data: state.tktaikhoan.tktaikhoanList,
        meta: state.tktaikhoan.meta
    };
};
const TKTaiKhoanNhomNDForm = connect(mapStateToProps, TKTaiKhoanAction)(TKTaiKhoanNhomNDFormComponent);
export { TKTaiKhoanNhomNDForm };