
import * as Constants from './cauhoikhaosatConstant';

export function setData(cauhoikhaosatList) {
    return {
        type: Constants.CAUHOIKHAOSAT_SET_LIST_DATA,
        cauhoikhaosatList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.CAUHOIKHAOSAT_SET_LIST_META,
        meta
    };
}