import * as actions from './hosovuviecConstants';

export function setData(hosovuviecList) {
    return {
        type: actions.HOSOVUVIEC_SET_LIST_DATA,        
        hosovuviecList
    };
}
export function setDataHVVP(dataHVVP) {
    return {
        type: actions.HANHVIVIPHAM_SET_LIST_DATA,        
        dataHVVP
    };
}
export function changeStatusDataHVVP(idHVVP) {
    return {
        type: actions.HANHVIVIPHAM_CHANGESTATUS,        
        idHVVP
    };
}
export function setMeta(meta) {
    return {
        type: actions.HOSOVUVIEC_SET_LIST_META,
        meta
    };
}