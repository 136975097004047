
import * as Constants from './templateimportConstant';

export function setData(templateimportList) {
    return {
        type: Constants.TEMPLATEIMPORT_SET_LIST_DATA,
        templateimportList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TEMPLATEIMPORT_SET_LIST_META,
        meta
    };
}