
import * as Constants from './dmchitieusanphamquantamConstant';

export function setData(dmchitieusanphamquantamList) {
    return {
        type: Constants.DMCHITIEUSANPHAMQUANTAM_SET_LIST_DATA,
        dmchitieusanphamquantamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHITIEUSANPHAMQUANTAM_SET_LIST_META,
        meta
    };
}