export const API_URL = window.appCfg.API_URL;
export const SSO = {
    WEB_ENDPOINT_SSO: "http://localhost:3000/loginsso",
    URL_REDIRECT_SSO: "http://sso.dms.gov.vn/cas/login?service=http://localhost:3000/loginsso",
    URL_LOGOUT_SSO: "https://sso.dms.gov.vn/cas/logout?service=http://localhost:3000",
};
export const TCKFINDER_URL = "http://admin.thatgia.vn/tckfinder/CkFinder.aspx?hog=http://api.thatgia.vn/&container=anhdaidien-tindang-null&type=ckeditor";
export const CLIENT = {
    client_id: 'EPS',
    client_secret: 'b0udcdl8k80cqiyt63uq',
};
export const AUTHORIZATION_BASE = 'Basic RVBTLklOVEVSTkFMOkl4ckFqRG9hMkZxRWxPN0loclNyVUpFTGhVY2tlUEVQVnBhZVBsU19YYXc=';

export const TYPE_TOAST = {
    SUCCESS: 'success',
    INFO: 'info',
    DANGER: 'danger',
    WARNING: 'warning',
    PRIMARY: 'primary',
    DARK: 'dark',
}
export const ObjectPer = {
    TinNoiBat: "001",
    SanPham: "002",
    VuViecViPham: "003",
    CauHoi: "004",
    CauTraLoi: "005",
    DoanhNghiep: "006",
    ToChucCaNhanViPham: "007",
    HuongDanSuDung: "008",
    GioiThieuHeThong: "009",
    DieuKhoan: "010",
    TaiKhoan: "011",
    SuKien: "012",
    VuViecViPhamTheoDiaBan: "013",
    HoiDapTheoLinhVuc: "014",
    DiaBanXayRaViPham: "015",
    DoanhNghiepTichCuc: "016",
    SoLuotTruyCap: "017",
    SanPhamLienQuan: "018",
    SanPhamHayBiLamGia: "019",
    XuHuongTimKiem: "020",
    DoanhNghiepHoatDong: "021",
    SuKienLienQuan: "022",
    TinBaoToGiac: "023",
    DauHieuNhanBiet: "024",
    LienHeHoTro: "025",
    CauHoiThuongGap: "026",
    ThuongHieuNoiBat: "027",
    TuLieuHangGia: "028",
    LichSuQuetMa: "029",
    LichSuQuetTem: "030",
    LichSuQuetQR: "031",
    LoiMoiTheoDoi: "032",
    BaiDang: "033",
    BinhLuanBaiDang: "034",
    PhanHoiBinhLuan: "035",
    LuotThich: "036",
    LuotChiaSe: "037",
    LuotBaoCaoViPham: "038",
    ThongBao: "039",
    TinNhan: "040",
    TinTraLoi: "041",
    KetNoi: "042",
    TheoDoi: "043",
    TuLieuDongGop: "044",
    PhanHoiTinBao: "045",
    DuLieuDanhMucLayTuHeThongKhac: "046",
    DuLieuDanhMucDongBo: "047",
    DuLieuDanhMucHeThongTuQuanLy: "048",
    DuLieuLienKet: "049",
    ThongSoHeThong: "050",
}
export const PermissonList = {
    NoPer: "1",
    OnlyPer: "2",
    AllPer: "3"
}
export const PrivilegeList = {
    ViewList: "01",
    ViewDetail: "02",
    ViewStatisticDetail: "03",
    ViewStatisticOverview: "04",
    Export: "05",
    ViewGarena: "06",
    Add: "07",
    Edit: "08",
    Delete: "09",
    Approved: "10",
    Hide: "11",
    Close: "12",
    TurnOff: "13",
    Import: "14",
}