
import * as Constants from './dieukhoanchinhsachConstant';

export function setData(dieukhoanchinhsachList) {
    return {
        type: Constants.DIEUKHOANCHINHSACH_SET_LIST_DATA,
        dieukhoanchinhsachList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DIEUKHOANCHINHSACH_SET_LIST_META,
        meta
    };
}