
import * as Constants from './dmdoituongchiaseConstant';

export function setData(dmdoituongchiaseList) {
    return {
        type: Constants.DMDOITUONGCHIASE_SET_LIST_DATA,
        dmdoituongchiaseList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMDOITUONGCHIASE_SET_LIST_META,
        meta
    };
}