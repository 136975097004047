
import * as Constants from '../taikhoan/taikhoanConstant';

export function setData(taikhoanList) {
    return {
        type: Constants.TAIKHOAN_SET_LIST_DATA,
        taikhoanList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TAIKHOAN_SET_LIST_META,
        meta
    };
}