
import * as actions from './tktaikhoanConstant';

export function setData(tktaikhoanList) {
    return {
        type: actions.TKTAIKHOAN_SET_LIST_DATA,
        tktaikhoanList
    };
}

export function setMeta(meta) {
    return {
        type: actions.TKTAIKHOAN_SET_LIST_META,
        meta
    };
}