
import * as Constants from './videoConstant';

export function setData(videoList) {
    return {
        type: Constants.VIDEO_SET_LIST_DATA,
        videoList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.VIDEO_SET_LIST_META,
        meta
    };
}