
import * as Constants from './thnhanhieuConstant';

export function setData(thnhanhieuList) {
    return {
        type: Constants.THNHANHIEU_SET_LIST_DATA,
        thnhanhieuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.THNHANHIEU_SET_LIST_META,
        meta
    };
}