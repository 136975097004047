
import * as Constants from './ketquaphieukhaosatConstant';

export function setData(ketquaphieukhaosatList) {
    return {
        type: Constants.KETQUAPHIEUKHAOSAT_SET_LIST_DATA,
        ketquaphieukhaosatList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.KETQUAPHIEUKHAOSAT_SET_LIST_META,
        meta
    };
}