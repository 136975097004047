
import * as Constants from './dmtinhthanhConstant';

export function setData(dmtinhthanhList) {
    return {
        type: Constants.DMTINHTHANH_SET_LIST_DATA,
        dmtinhthanhList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMTINHTHANH_SET_LIST_META,
        meta
    };
}