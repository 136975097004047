import * as actions from './tindangConstants';

export function setData(tindangList) {
    return {
        type: actions.TINDANG_SET_LIST_DATA,
        tindangList
    };
}

export function setMeta(meta) {
    return {
        type: actions.TINDANG_SET_LIST_META,
        meta
    };
}