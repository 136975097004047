import * as actions from './binhluanConstants';

export function setData(binhluanList) {
    return {
        type: actions.BINHLUAN_SET_LIST_DATA,
        binhluanList
    };
}

export function setMeta(meta) {
    return {
        type: actions.BINHLUAN_SET_LIST_META,
        meta
    };
}