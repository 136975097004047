
import * as Constants from './chcauhoithuonggapConstant';

export function setData(chcauhoithuonggapList) {
    return {
        type: Constants.CHCAUHOITHUONGGAP_SET_LIST_DATA,
        chcauhoithuonggapList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.CHCAUHOITHUONGGAP_SET_LIST_META,
        meta
    };
}