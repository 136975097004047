
import * as Constants from './dmchucvuConstant';

export function setData(dmchucvuList) {
    return {
        type: Constants.DMCHUCVU_SET_LIST_DATA,
        dmchucvuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHUCVU_SET_LIST_META,
        meta
    };
}