
import * as Constants from './dmhanhviviphamConstant';

export function setData(dmhanhviviphamList) {
    return {
        type: Constants.DMHANHVIVIPHAM_SET_LIST_DATA,
        dmhanhviviphamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMHANHVIVIPHAM_SET_LIST_META,
        meta
    };
}