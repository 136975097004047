import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { string, object, ref } from 'yup';
import { LoadingComponent } from 'shared/components';
import { Context } from '../TKTaiKhoanService';


const schema = object({
    OldPassword: string().trim().required('Bạn chưa nhập mật khẩu cũ').min(6, 'Bạn nhập tối thiểu 6 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự'),
    NewPassword: string().trim().required('Bạn phải nhập mật khẩu mới').min(6, 'Bạn nhập tối thiểu 6 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự').matches(/(?=^.{6,30}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Mật khẩu phải có ít nhất một chữ số, một chữ cái thường, một chữ cái in hoa, một ký tự đặc biệt'),
    NewPasswordConfirm: string().trim().required('Bạn chưa nhập lại mật khẩu').oneOf([ref('NewPassword'), null], 'Mật khẩu nhập lại không đúng'),
});

const userDefault = {
    OldPassword: '',
    NewPassword: '',
    NewPasswordConfirm: '',
};
class UserFormChangePasswordComponent extends Component {
    static propTypes = {
        onClose: PropTypes.func
    };
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            data: userDefault,
            listGroup: [],
            editMode: false,
            loading: false,
            isShow: false,
            action: 'new',
            id: null,

        };
        this.subscriptions = {};

        //this.url = props ? props.url : '';
        //this.http = http;
    }

    componentDidMount() {
        this.subscriptions['sendToFormChangePassword'] = this.context.tktaikhoanService.sendToFormChangePassword.subscribe(res => {
            switch (res.action) {
                case 'changePass':
                    this.setState({ editMode: true, isShow: res.isShow, action: res.action, id: res.id, data: userDefault });
                    break;
                default:
                    this.setState({ editMode: true, isShow: res.isShow, action: 'read', id: null, data: userDefault });
                    break;
            }
        });

    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    onSubmit(data) {
        this.setState({
            loading: true
        });
        this.subscriptions['changePass'] = this.context.tktaikhoanService.changePassword(data.OldPassword, data.NewPassword, data.NewPasswordConfirm).subscribe(() => {
            this.context.beh.toast('Đổi mật khẩu thành công');
            this.handleClose(true);
            this.setState({
                loading: false
            });
              
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error.error);
                });
    }
    onEdit() {
        this.setState({
            editMode: true
        });
    }
    handleClose(isRefesh) {
        this.setState({ isShow: false, data: userDefault });
        if (this.props.onClose) {
            this.props.onClose(isRefesh);
        }

    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    computedTitle() {
        return 'Đổi mật khẩu';
    }
    render() {
        return (

            <Modal size="md" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.handleClose.bind(this)}>
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => {
                        this.onSubmit(values);
                    }}
                    enableReinitialize={true}
                    initialValues={this.state.data}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                            <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress.bind(this)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.computedTitle()}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" controlId="OldPassword">
                                            <Form.Label>Mật khẩu cũ</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            <Form.Control
                                                type="password"
                                                name="OldPassword"
                                                value={values.OldPassword || ''}
                                                onChange={(ev) => { handleChange(ev); handleBlur(ev); }}
                                                isInvalid={touched.OldPassword && !!errors.OldPassword}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.OldPassword}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" controlId="NewPassword">
                                            <Form.Label>Mật khẩu mới</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            <Form.Control
                                                type="password"
                                                name="NewPassword"
                                                value={values.NewPassword || ''}
                                                onChange={(ev) => { handleChange(ev); handleBlur(ev); }}
                                                isInvalid={touched.NewPassword && !!errors.NewPassword}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.NewPassword}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="NewPasswordConfirm">
                                            <Form.Label>Nhập lại mật khẩu mới</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            <Form.Control
                                                type="password"
                                                name="NewPasswordConfirm"
                                                value={values.NewPasswordConfirm || ''}
                                                onChange={handleChange}
                                                isInvalid={touched.NewPasswordConfirm && !!errors.NewPasswordConfirm}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.NewPasswordConfirm}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                   
                                    {this.state.editMode ?
                                        <Button type="submit">Lưu lại</Button>
                                        :
                                        <React.Fragment>
                                            <Button variant="primary" className="ml-2" type="button" onClick={this.onEdit.bind(this)}>Sửa</Button>
                                        </React.Fragment>
                                    }
                                    <Button variant="default" onClick={() => { this.handleClose(false); }}>
                                        Đóng
                                </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                </Formik>
            </Modal>
        );
    }
}
export { UserFormChangePasswordComponent };