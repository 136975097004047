
const checkContent = (strValue, LstCheck) => {
    var temp = 0;
    if (strValue && LstCheck && LstCheck.length > 0) {
        for (var i = 0; i < LstCheck.length; i++) {
            if (strValue.includes(LstCheck[i].title)) {
                temp += 1;
            }
        }
    }
    if (temp > 0) {
        return true;
    } else {
        return false;

    }
}
const markMatches = (res, lsttk) => {
    if (lsttk.length >0){
        for(var i=0;i<lsttk.length;i++){
            const req = lsttk[i].title;
            if (req) {
                // const normReq = req
                //     .toLowerCase()
                //     .replace(/\s+/g, " ")
                //     .trim()
                //     .split(" ")
                //     .sort((a, b) => b.length - a.length);
                const normReq = [{req}] ;
                res = res.replaceAll(req,`<span class="mark-color">` + req + `</span>`)
                // res = res.replace(
                //     new RegExp(`(${normReq.join("|")})`, "gi"),
                //     match => `<span class="mark-color">` + match + `</span>`
                // );
            }
        }
       
    }
  
    return res;
}
export { checkContent, markMatches }
