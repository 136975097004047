import * as Constant from './thdauhieunhanbietConstant';

const thdauhieunhanbiet = {
    thdauhieunhanbietList: [],
    meta: {
        page: 1,
        page_size: 20,
        sort: {id:'asc'},
        search: ''        
    }
};
const thdauhieunhanbietReducer = (state = thdauhieunhanbiet, action) => {
    switch (action.type) {
        case Constant.THDAUHIEUNHANBIET_SET_LIST_DATA:
            return Object.assign({}, state, { thdauhieunhanbietList: action.thdauhieunhanbietList });
        case Constant.THDAUHIEUNHANBIET_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { thdauhieunhanbietReducer};