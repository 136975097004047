import { http } from '../utils';

class BaseService {
    constructor(props = {}) {
        this.url = props ? props.url : '';
        this.http = http;
    }
    getMany(props) {
        const { page, page_size, sort, search, filter } = props;
        let sortBy = '';
        let sortDesc = false;
        let title = search; // do api chua co tim kiem theo tu khoa nen de tam search se tim kiem theo username
        if (sort) {
            sortBy = Object.keys(sort)[0];
            sortDesc = sort[sortBy] === 'desc';
        }
        const params = Object.assign({}, {
            page: page,
            itemsPerPage: page_size,
            sortBy: sortBy,
            sortDesc: sortDesc,
            FilterText: title
        }, filter);
        return http.get(`${this.url}`, { params: params });
    }
    getById(id) {
        return http.get(`${this.url}/${id}`);
    }
    create(obj) {
        return http.post(`${this.url}`, obj);
    }
    update(obj, id) {
        return http.put(`${this.url}/${id}`, obj);
    }
    createFormData(obj, files, objcreate) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        }
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append(`files`, files[i])
        }
        formData.append(objcreate, JSON.stringify(obj));
        return http.post(`${this.url}`, formData, {
            headers: headers
        })
    }
    updateFormData(obj, files, id, objupdate) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        }
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append(`files`, files[i])
        }
        formData.append(objupdate, JSON.stringify(obj));
        return http.put(`${this.url}/${id}`, formData, {
            headers: headers
        })
    }
    del(id) {
        return http.delete(`${this.url}/${id}`);
    }
    formatDateTime(strValue) {
        if (!strValue) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = (day >= 10 ? '' : '0') + day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (output == '01/01/1')
                return '';
            return output;
        }
    }
    formatDateTimeMDY(strValue) {
        if (strValue == null || strValue === "") {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
            var output = (('' + month).length < 2 ? '0' : '') + month + '/' +
                day + '/' +
                d.getFullYear();
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    /**
     * Kiểm tra có quyền không
     * @param {any} lstRoles danh sách quyền
     * @param {any} strObjectPer mã đối tượng
     * @param {any} strPrivilege mã quyền
     * @param {any} strPermisson Value quyền 
     */
    checkPermisson(lstRoles, strObjectPer, strPrivilege, strPermisson) {
        if (lstRoles.length > 0) {
            for (var i = 0; i < lstRoles.length; i++) {
                if (lstRoles[i] == (strObjectPer + "-" + strPrivilege + "-" + strPermisson))
                    return true;
            }
        }
        return false;
    }
    formatFullDateTime(strValue) {
        if (strValue == undefined || strValue === "" || strValue == null) {
            return '';
        }
        else {
            let output = '';
            let d = new Date(strValue);
            let month = (d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1);
            let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
            let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
            let minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
            output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (hour > 0) {
                output = output + ' ' + hour + ":" + minute;
            }
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    //ChageFormat datetime yyyy-mm-dd phục vụ tìm kiếm mở rộng
    changeFormatDateTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();

            var output = d.getFullYear() + '-' +
                (('' + month).length < 2 ? '0' : '') + month + '-' +
                (('' + day).length < 2 ? '0' : '') + day;
            return output;
        }
    }
    formartPriceVND(strNumber) {
        return strNumber ? strNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : strNumber;
    }
    // dels(listId = []): Observable<any> {
    //     //return this.http.post<any>(this.url + '/deletes', listId);
    // }
}

export { BaseService };
