import * as Constant from './chcauhoithuonggapConstant';

const chcauhoithuonggap = {
    chcauhoithuonggapList: [],
    meta: {
        page: 1,
        page_size: 20,
        sort: {id:'asc'},
        search: ''        
    }
};
const chcauhoithuonggapReducer = (state = chcauhoithuonggap, action) => {
    switch (action.type) {
        case Constant.CHCAUHOITHUONGGAP_SET_LIST_DATA:
            return Object.assign({}, state, { chcauhoithuonggapList: action.chcauhoithuonggapList });
        case Constant.CHCAUHOITHUONGGAP_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { chcauhoithuonggapReducer};