import * as Constant from './dmloaisanphamConstant';

const dmloaisanpham = {
    dmloaisanphamList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            Lock: -1
        },
    }
};
const dmloaisanphamReducer = (state = dmloaisanpham, action) => {
    switch (action.type) {
        case Constant.DMLOAISANPHAM_SET_LIST_DATA:
            return Object.assign({}, state, { dmloaisanphamList: action.dmloaisanphamList });
        case Constant.DMLOAISANPHAM_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {dmloaisanphamReducer};