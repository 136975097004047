import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { API_URL } from 'app-setting';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
class TKTaiKhoanService extends BaseService {

    constructor(props) {
        const _props = Object.assign({}, { url: 'api/tktaikhoan' }, props);
        super(_props);
        this.sendToForm = new Subject();
        this.sendToFormChangePassword = new Subject();
        this.sendToFormAction = new Subject();
        this.sendToFormView = new Subject();
        this.sendToFormBaoCaoViPham= new Subject();
        this.sendToFormPH= new Subject();
    }
    exportExcel(obj) {
        window.open(`${API_URL}api/tktaikhoan/export?nam=${obj.nam}&thang=${obj.thang}&FilterText=${obj.keyword}`, '_blank');
    }
    deleteUser(id) {
        return http.put(`${API_URL}api/tktaikhoan/delete/${id}`);
    }
    changeStatus(id, status) {
        return http.put(`${API_URL}api/tktaikhoan/changestatus/${id}`, { id: id, trangthai: status });
    }
    approved(id) {
        return http.put(`${API_URL}api/tktaikhoan/approved/${id}`);
    }
    changePassword(OldPassword, NewPassword, NewPasswordConfirm) {
        return http.put(`${API_URL}api/users/password`, { OldPassword: OldPassword, NewPassword: NewPassword, NewPasswordConfirm: NewPasswordConfirm });
    }
    getNguoiDungNhomND(idnhom, idtaikhoan) {
        return http.get(`api/tknguoidungnhomnd?&SortBy=id&SortDesc=false&ItemsPerPage=0&IdNhom=${idnhom}&idUser=${idtaikhoan}&mode=1`);
    }
    addNguoiDungNhomND(idnhom, manhom, idtaikhoan) {
        return http.post('api/tknguoidungnhomnd', { title: " ", iD_NHOMNGUOIDUNG: idnhom, mA_NHOMNGUOIDUNG: manhom, iD_TAIKHOAN: idtaikhoan });
    }
    delNguoiDungNhomND(id) {
        return http.delete(`api/tknguoidungnhomnd/${id}`);
    }

    getAllNhomNguoidung() {
        return http.get('api/tknhomnguoidung?nmanhom=03').pipe(map((res) => {// khong lay ma nhom DN    
            const data=res.data.filter(
                x => x.manhom === '04'||x.manhom === '05'||x.manhom === '06'
              );
            if (data) {
                return data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }

    getTaiKhoan() {
        return http.get(`${API_URL}api/tktaikhoan`);
    }
    getListStatus() {
        var data = [{
            value: 1, label: 'Tạo mới'
        },
        {
            value: 2, label: 'Hoạt động'
        },
        {
            value: 3, label: 'Bị khóa'
        }]
        return data;
    }
    getListLoaiTaiKhoan() {
        var data = [{
            value: 1, label: 'Quản trị hệ thống'
        },
        {
            value: 2, label: 'Quản lý thị trường'
        },
        {
            value: 3, label: 'Doanh nghiệp'
        },
        {
            value: 4, label: 'Cá nhân'
        }];
        return data;
    }
    getByUsername(obj) {
        return http.get(`api/tktaikhoan/getbyusername/${obj}`);
    }
    getByIdUpdate(id) {
        return http.get(`${API_URL}api/tktaikhoan/getbyidiupdate/${id}`);
    }
    getDmTinhThanh() {
        return http.get('api/dmtinhthanh?page=1&itemsPerPage=-1').pipe(map((res) => {
            if (res.data) {
                return res.data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }

    //báo cáo tài khoản vi phạm
    getBaoCaoById(id){
        return http.get(`${API_URL}api/tkbaocaotkvipham/${id}`);
    }
    getManyBaoCaoViPham(props) {
        const { page, page_size, sort, search, filter } = props;
        let sortBy = '';
        let sortDesc = false;
        let title = search;
        if (sort) {
            sortBy = Object.keys(sort)[0];
            sortDesc = sort[sortBy] === 'desc';
        }
        const params = Object.assign({}, {
            page: page,
            itemsPerPage: page_size,
            sortBy: sortBy,
            sortDesc: sortDesc,
            FilterText: title
        }, filter);
        return http.get(`api/tkbaocaotkvipham`, { params: params });
    }
    changeStatusBC(id, status) {
        return http.put(`${API_URL}api/tkbaocaotkvipham/changestatus`, { id: id, trangthai: status });
    }
    phanhoi(obj, id) {
        return http.put(`api/tkbaocaotkvipham/phanhoi/${id}`, obj);
    }
}
const tktaikhoanService = new TKTaiKhoanService();
const Context = createContext();

export { Context, tktaikhoanService, TKTaiKhoanService };