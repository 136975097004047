
import * as Constants from './dnthuonghieuConstant';

export function setData(dnthuonghieuList) {
    return {
        type: Constants.DNTHUONGHIEU_SET_LIST_DATA,
        dnthuonghieuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DNTHUONGHIEU_SET_LIST_META,
        meta
    };
}