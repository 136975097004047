
import { DM_KHOANG_GIA_SET_LIST_DATA, DM_KHOANG_GIA_SET_LIST_META } from './DMKhoangGiaConstant';


export function setData(DMKhoangGiaList) {
    return {
        type: DM_KHOANG_GIA_SET_LIST_DATA,
        DMKhoangGiaList: DMKhoangGiaList
    };
}

export function setMeta(meta) {
    return {
        type: DM_KHOANG_GIA_SET_LIST_META,
        meta: meta
    };
}