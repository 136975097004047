import * as Constant from './phieukhaosatConstant';

const phieukhaosat = {
    phieukhaosatList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: '',
        filter: {
            ID_DMLinhVucHoatDong: 0,
        },
    }
};
const phieukhaosatReducer = (state = phieukhaosat, action) => {
    switch (action.type) {
        case Constant.PHIEUKHAOSAT_SET_LIST_DATA:
            return Object.assign({}, state, { phieukhaosatList: action.phieukhaosatList });
        case Constant.PHIEUKHAOSAT_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { phieukhaosatReducer};