
import * as Constants from './thdauhieunhanbietConstant';

export function setData(thdauhieunhanbietList) {
    return {
        type: Constants.THDAUHIEUNHANBIET_SET_LIST_DATA,
        thdauhieunhanbietList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.THDAUHIEUNHANBIET_SET_LIST_META,
        meta
    };
}