
import { DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_DATA, DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_META } from './DMChiTieuNguoiDungTichCucConstant';


export function setData(DMChiTieuNguoiDungTichCucList) {
    return {
        type: DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_DATA,
        DMChiTieuNguoiDungTichCucList: DMChiTieuNguoiDungTichCucList
    };
}

export function setMeta(meta) {
    return {
        type: DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_META,
        meta: meta
    };
}