
import * as Constants from './vanbanphapluatConstant';

export function setData(vanbanphapluatList) {
    return {
        type: Constants.VANBANPHAPLUAT_SET_LIST_DATA,
        vanbanphapluatList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.VANBANPHAPLUAT_SET_LIST_META,
        meta
    };
}