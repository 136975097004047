import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, Card, Button, ButtonGroup, Form, Alert, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Page, GridView, FormSelect } from 'shared/components';
import { Context } from '../TKTaiKhoanService';
import { TKTaiKhoanAction } from '../../../redux/actions';
import { TKTaiKhoanFormComponent } from '../tktaikhoan-form/TKTaiKhoanFormComponent';
import { TYPE_TOAST } from 'app-setting';
import { http } from 'shared/utils';
class TKTaiKhoanList extends Component {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        setData: PropTypes.func,
        setMeta: PropTypes.func,
        data: PropTypes.array,
        meta: PropTypes.object
    }
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            dataNam: [],
            dataThang: [],
            loading: false,
            nam: '',
            objalert: {
                message: '',
                show: false,
                type: 'success'
            },
            type: '',
            dataTrangThai: [],
        };
        this.subscriptions = {};
    }
    componentDidMount() {
        this.fetchData(this.props.meta);
        var today = new Date();
        let dataNam = [];
        for (let i = today.getFullYear(); i > 1990; i--) {
            dataNam.push({ value: i, label: `Năm ${i}` });
        }
        //let dataThang = [];
        //for (let i = 0; i < 12; i++) {
        //    dataThang.push({ value: i + 1, label: `Tháng ${i + 1}` });
        //}
        this.setState({
            dataNam: dataNam,
            dataTrangThai: [{
                value: 1, label: 'Tạo mới/Chờ kích hoạt'
            },
            {
                value: 2, label: 'Hoạt động'
            },
            {
                value: 3, label: 'Bị khóa'
            },
            {
                value: 4, label: 'Chờ phê duyệt'
            }]
            // dataThang: dataThang,
        });
    }
    componentDidUpdate(prevProps) {
        // eslint-disable-next-line react/prop-types
        if (this.props.match.params.type !== prevProps.match.params.type) {
            this.fetchData(this.props.meta);
        }
    }
    onChangeSelectThang = (select) => {
        this.setState({
            selection: select.value
        });
        let dataThang = [];
        for (let i = 0; i < 12; i++) {
            dataThang.push({ value: i + 1, label: `Tháng ${i + 1}` });
        }
        this.setState({
            dataThang: dataThang,
        });

    };
    ClearMonth(evt) {
        if (evt == null) {
            this.setState({
                dataThang: [],
            })
        }

    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    fetchData(meta) {
        let type = this.props.match.params.type;
        let strgroup = '';
        if (type) {
            if (type === "ntd") {
                strgroup = "02";
            }
            else if (type === "qltt") {
                strgroup = "04,05,06";
            }
        }
        meta.filter.groups = strgroup;
        meta.filter.nmanhom = "03"; // khong lay tk doanh nghiep
        this.setState({ loading: true });
        this.subscriptions['getmany'] = this.context.tktaikhoanService.getMany(meta).subscribe(res => {
            this.props.setData(res.data || []);
            this.props.setMeta({
                page: res.currentPage,
                page_size: res.pageSize,
                total: res.totalRows
            });
            this.setState({ loading: false });
        }, err => {
            console.log(err);
            this.setState({ loading: false });
        });
    }

    handleChange(res) {
        let newMeta = {};
        switch (res.event) {
            case 'changeSelected':
                break;
            case 'changePageSize':
                newMeta = Object.assign({}, this.props.meta, res.data);
                if (this.props.meta.page_size !== newMeta.page_size) {
                    // this.props.setMeta({ page_size: newMeta.page_size });
                    this.fetchData(newMeta);
                }
                break;
            case 'changePage':
                newMeta = Object.assign({}, this.props.meta, res.data);
                if (this.props.meta.page !== newMeta.page) {
                    // this.props.setMeta({ page: newMeta.page });
                    this.fetchData(newMeta);
                }
                break;
            case 'changeSort':
                newMeta = Object.assign({}, this.props.meta, res.data);
                if (this.props.meta.sort !== newMeta.sort) {
                    this.props.setMeta({ sort: newMeta.sort });
                    this.fetchData(newMeta);
                }
                break;
            case 'changeKeySearch':
                res.data.page = 1;
                newMeta = Object.assign({}, this.props.meta, res.data);
                this.props.setMeta({
                    search: newMeta.search,
                });
                this.fetchData(newMeta);
                break;
            case 'changeFilter':
                res.data.page = 1;
                newMeta = Object.assign({}, this.props.meta, res.data);
                this.props.setMeta({
                    filter: newMeta.filter
                });
                this.fetchData(newMeta);

                break;
            default:
                break;
        }
    }

    handleClose(res) {
        if (res) {
            //let newMeta = Object.assign({}, this.props.meta, { page: 1 });
            let newMeta = Object.assign({}, this.props.meta);
            this.props.setMeta({
                page: newMeta.page
            });
            this.fetchData(newMeta);
        }
    }
    addNew() {
        this.context.tktaikhoanService.sendToForm.next({
            id: null,
            action: 'new',
            isShow: true
        });
    }
    viewDetail(item) {
        this.context.tktaikhoanService.sendToForm.next({
            id: item.id,
            action: 'read',
            isShow: true
        });

    }
    viewEdit(item) {
        this.context.tktaikhoanService.sendToForm.next({
            id: item.id,
            action: 'edit',
            isShow: true
        });
    }
    async delete(item) {
        if (await this.context.beh.confirm('Bạn có muốn xóa bản ghi này')) {
            this.setState({
                loading: true
            });
            this.context.tktaikhoanService.deleteUser(item.id).subscribe(() => {
                if (this.props.data.length === 1 && this.props.meta.page !== 1) {
                    this.props.setMeta({ page: this.props.meta.page - 1 });
                }
                this.fetchData(this.props.meta);
                this.setState({
                    loading: false
                });
                this.context.beh.toast('Xóa tài khoản thành công');
            });
        }
    }

    async UnLock(id) {
        if (await this.context.beh.confirm('Bạn có muốn kích hoạt tài khoản này?')) {
            this.setState({
                loading: true
            });
            this.context.tktaikhoanService.changeStatus(id, 2).subscribe(() => {
                this.fetchData(this.props.meta);
                this.setState({
                    loading: false
                });
                this.context.beh.toast('Kích hoạt tài khoản thành công');

            });
        }
    }
    async Approved(id) {
        if (await this.context.beh.confirm('Bạn có muốn phê duyệt tài khoản này?')) {
            this.setState({
                loading: true
            });
            this.context.tktaikhoanService.approved(id).subscribe(() => {
                this.fetchData(this.props.meta);
                this.setState({
                    loading: false
                });
                this.context.beh.toast('Phê duyệt tài khoản thành công');

            });
        }
    }
    async Lock(id) {
        if (await this.context.beh.confirm('Bạn có muốn khóa tài khoản này?')) {
            this.setState({
                loading: true
            });
            this.context.tktaikhoanService.changeStatus(id, 3).subscribe(() => {
                this.fetchData(this.props.meta);
                this.setState({
                    loading: false
                });
                this.context.beh.toast('Khóa tài khoản thành công');
            });
        }
    }
    Export() {
        var param = this.props.meta;
        var obj = {
            nam: param.filter.nam != null ? param.filter.nam : 0,
            thang: param.filter.thang != null ? param.filter.thang : 0,
            keyword: param.search
        };
        this.context.tktaikhoanService.exportExcel(obj);
    }
    formatDateTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            return output;
        }
    }
    showAlert(mess, type) {
        this.setState({
            objalert: {
                message: mess,
                show: true,
                type: type
            }
        });
        setTimeout(() => {
            this.setState({
                objalert: {
                    show: false
                }
            });
        }, 5000);
    }
    closeAlert() {
        this.setState({
            objalert: {
                show: false
            }
        });
    }
    dongBoTaiKhoan() {
        this.setState({
            loading: true
        });
        http.get(`api/users/dongbo`).subscribe(
            res => { // then print response status
                this.setState({
                    loading: false
                });
                this.context.beh.alert('Đồng bộ thành công');
            },
            error => {
                this.setState({
                    loading: false
                });
                this.context.beh.alert('Đã có lỗi xảy ra');
            }
        )
    }
    render() {
        return (
            <Page>
                <Page.Header>
                    <Row className="mb-2">
                        <Col sm={6}>
                            <h5>Danh sách tài khoản</h5>
                        </Col>
                        <Col sm={6}>
                            <Breadcrumb className="float-sm-right">
                                <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/thongketongquan' }}>Trang chủ</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Danh mục tài khoản
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Page.Header>
                <Page.Content>

                    <Card>
                        <Card.Body>
                            <GridView
                                loading={this.state.loading}
                                handleChange={this.handleChange.bind(this)}>

                                <GridView.Header
                                    keySearch={this.props.meta.search}
                                    ActionBar={
                                        <React.Fragment>
                                            <Button title="Thêm mới" variant="primary" size="sm" onClick={this.addNew.bind(this)} >
                                                <span className="iconify fa" data-icon="fa-solid:plus" data-inline="false"></span>
                                        Thêm mới
                                            </Button>
                                            <Button title="Xuất excel" size="sm" className="ml-2" type="button" variant="success" onClick={() => { this.Export(); }}>
                                                <span className="iconify" data-icon="fa-solid:file-export" data-inline="false" />
                                            Export
                                            </Button>
                                            {this.props.match.params.type === "qltt" ? <Button variant="success" className="ml-2" size="sm" onClick={this.dongBoTaiKhoan.bind(this)} >
                                                <span className="iconify fa" data-icon="fa-solid:sync" data-inline="false"></span>
                                        Đồng bộ
                                        </Button>:""}
                                        </React.Fragment>
                                    }
                                    AdvanceFilter={
                                        <Formik onSubmit={(values) => {
                                            this.handleChange({ event: 'changeFilter', data: { filter: values } });
                                        }}
                                            enableReinitialize={true}
                                            initialValues={this.props.meta.filter}
                                        >
                                            {({ handleSubmit, handleChange, values, setFieldValue }) => (
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col sm={'auto'} className="text-right">Năm</Col>
                                                        <Col sm={2}>
                                                            <FormSelect className="form-control-sm"
                                                                placeholder="Chọn năm"
                                                                name="nam"
                                                                data={this.state.dataNam}
                                                                mode="simpleSelect"
                                                                value={values.nam}
                                                                onChange={(evt) => {
                                                                    if (evt.target.value != null) {
                                                                        setFieldValue('nam', evt.target.value);
                                                                        this.onChangeSelectThang(evt);
                                                                    }
                                                                    else {
                                                                        setFieldValue('nam', null);
                                                                        setFieldValue('thang', null);
                                                                    }
                                                                    this.ClearMonth(evt.target.value);
                                                                }}


                                                            ></FormSelect>
                                                        </Col>
                                                        <Col sm={'auto'} className="text-right">Tháng</Col>
                                                        <Col sm={2}>
                                                            <FormSelect className="form-control-sm"
                                                                name="thang"
                                                                placeholder="Chọn tháng"
                                                                data={this.state.dataThang}
                                                                mode="simpleSelect"
                                                                value={values.thang}
                                                                onChange={handleChange}
                                                            ></FormSelect>
                                                        </Col>
                                                        <Col sm={'auto'} className="text-right">Trạng thái</Col>
                                                        <Col sm={3}>
                                                            <FormSelect className="form-control-sm"
                                                                placeholder="Chọn trạng thái"
                                                                name="trangthai"
                                                                data={this.state.dataTrangThai}
                                                                mode="simpleSelect"
                                                                value={values.trangthai}
                                                                onChange={handleChange}
                                                            ></FormSelect>
                                                        </Col>
                                                        <Col sm={3} className="">
                                                            <Button size="sm" type="submit" variant="primary">
                                                                <span className="iconify" data-icon="fa-solid:search" data-inline="false" />
                                                                Tìm kiếm
                                                            </Button>

                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    }
                                >
                                </GridView.Header>
                                <GridView.Table
                                    className="col-12"
                                    noSelected={true}
                                    data={this.props.data}
                                    keyExtractor={({ item }) => {
                                        return item.id;
                                    }}
                                    sort={this.props.meta.sort}
                                    page={this.props.meta.page}
                                    page_size={this.props.meta.page_size}
                                    total={this.props.meta.total}
                                >
                                    <GridView.Table.Column style={{ width: '20px' }}
                                        title="STT"
                                        className="text-center"
                                        body={({ index }) => (
                                            <span>{index + 1 + (this.props.meta.page - 1) * this.props.meta.page_size}</span>
                                        )} />
                                    <GridView.Table.Column style={{}}
                                        title="Họ và tên"
                                        sortKey="title"
                                        body={({ item }) => (
                                            <span>{item.title}</span>
                                        )} />
                                    <GridView.Table.Column style={{}}
                                        title="Tên đăng nhập"
                                        sortKey="tendangnhap"
                                        body={({ item }) => (
                                            <span>{item.tendangnhap}</span>
                                        )} />

                                    <GridView.Table.Column
                                        title="Điện thoại"
                                        sortKey="dienthoai"
                                        body={({ item }) => (
                                            <span>{item.dienthoai}</span>
                                        )} />
                                    <GridView.Table.Column
                                        title="Ngày đăng ký"
                                        sortKey="ngaytao"
                                        body={({ item }) => (
                                            <span>{this.formatDateTime(item.ngaytao)}</span>
                                        )} />
                                    <GridView.Table.Column
                                        title="Trạng thái"
                                        sortKey="trangthai"
                                        body={({ item }) => (
                                            <span>{item.trangthai === 1 ? 'Tạo mới/Chờ kích hoạt' : item.trangthai === 2 ? 'Hoạt động' : item.trangthai === 3 ? 'Bị khóa' : item.trangthai === 4 ? 'Chờ phê duyệt' : ''}</span>
                                        )} />
                                    <GridView.Table.Column style={{ width: '100px' }} className="view-action"
                                        title="Tác vụ"
                                        body={({ item }) => (
                                            <ButtonGroup size="sm">
                                                {
                                                    item.trangthai === 1 ? "" :
                                                        item.trangthai === 2 ? <Button title="Khóa tài khoản" variant="outline-danger" onClick={() => { this.Lock(item.id); }}>
                                                            <span className="iconify fa fa-lock" data-inline="false"></span>
                                                        </Button> : item.trangthai === 3 ? <Button title="Mở khóa tài khoản" variant="outline-success" onClick={() => { this.UnLock(item.id); }}>
                                                            <span className="iconify fa fa-unlock" data-inline="false"></span>
                                                        </Button> : ''

                                                }
                                                {
                                                    item.trangthai === 4 ? <Button title="Phê duyệt tài khoản" variant="outline-success" onClick={() => { this.Approved(item.id); }}>
                                                        <span className="iconify fa fa-check" data-inline="false"></span>
                                                    </Button> : ''
                                                }
                                                <Button title="Xem chi tiết" variant="outline-info" onClick={() => { this.viewDetail(item); }}>
                                                    <span className="iconify" data-icon="fa-solid:info-circle" data-inline="false"></span>
                                                </Button>
                                                <Button title="Sửa" variant="outline-info" onClick={() => { this.viewEdit(item); }}>
                                                    <span className="iconify" data-icon="fa-solid:edit" data-inline="false"></span>
                                                </Button>
                                                <Button title="Xóa" variant="outline-danger" onClick={() => { this.delete(item); }}>
                                                    <span className="iconify" data-icon="fa-solid:trash-alt" data-inline="false"></span>
                                                </Button>
                                            </ButtonGroup>
                                        )} />
                                </GridView.Table>
                            </GridView>

                        </Card.Body>
                    </Card>
                    <TKTaiKhoanFormComponent type={this.props.match.params.type} onClose={this.handleClose.bind(this)} />

                    <Alert show={this.state.objalert.show} variant={this.state.objalert.type} className="notification" onClose={() => this.closeAlert()} dismissible>{this.state.objalert.message}
                    </Alert>
                </Page.Content >
            </Page >

        );
    }
}
const mapStateToProps = (state) => {
    return {
        data: state.tktaikhoan.tktaikhoanList,
        meta: state.tktaikhoan.meta
    };
};
const TKTaiKhoanListComponent = connect(mapStateToProps, TKTaiKhoanAction)(TKTaiKhoanList);
export { TKTaiKhoanListComponent };