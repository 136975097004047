import * as actions from './baidangConstants';

export function setData(baidangList) {
    return {
        type: actions.BAIDANG_SET_LIST_DATA,
        baidangList
    };
}

export function setMeta(meta) {
    return {
        type: actions.BAIDANG_SET_LIST_META,
        meta
    };
}