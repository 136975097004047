const formatDateTime = (strValue) => { 
    if (strValue == undefined || strValue === "" || strValue == null) {
            return '';
        }
        else {
            let output = '';
            let d = new Date(strValue);
            let month = d.getMonth() + 1;
        let day = d.getDate();
        output = (day >= 10 ?'':'0') + day  + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (output == '01/01/1')
                return '';
            return output;
        }
    }
export { formatDateTime }