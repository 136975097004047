
import { DM_DON_VI_SET_LIST_DATA, DM_DON_VI_SET_LIST_META } from './DMDonViConstant';


export function setData(DMDonViList) {
    return {
        type: DM_DON_VI_SET_LIST_DATA,
        DMDonViList: DMDonViList
    };
}

export function setMeta(meta) {
    return {
        type: DM_DON_VI_SET_LIST_META,
        meta: meta
    };
}