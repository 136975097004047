
import * as Constants from './dmloaihinhkinhdoanhConstant';

export function setData(dmloaihinhkinhdoanhList) {
    return {
        type: Constants.DMLOAIHINHKINHDOANH_SET_LIST_DATA,
        dmloaihinhkinhdoanhList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMLOAIHINHKINHDOANH_SET_LIST_META,
        meta
    };
}