import * as actions from './binhluanspConstants';

export function setData(binhluanspList) {
    return {
        type: actions.BINHLUANSP_SET_LIST_DATA,
        binhluanspList
    };
}

export function setMeta(meta) {
    return {
        type: actions.BINHLUANSP_SET_LIST_META,
        meta
    };
}