
import * as Constants from './danhgiavehethongConstant';

export function setData(danhgiavehethongList) {
    return {
        type: Constants.DANHGIAVEHETHONG_SET_LIST_DATA,
        danhgiavehethongList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DANHGIAVEHETHONG_SET_LIST_META,
        meta
    };
}