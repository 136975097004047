
import * as Constants from './dmdiabanConstant';

export function setData(dmdiabanList) {
    return {
        type: Constants.DMDIABAN_SET_LIST_DATA,
        dmdiabanList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMDIABAN_SET_LIST_META,
        meta
    };
}