
import { DM_CHI_TIEU_DOANH_NGHIEP_TICH_CUC_SET_LIST_DATA, DM_CHI_TIEU_DOANH_NGHIEP_TICH_CUC_SET_LIST_META } from './DMChiTieuDoanhNghiepTichCucConstant';


export function setData(DMChiTieuDoanhNghiepTichCucList) {
    return {
        type: DM_CHI_TIEU_DOANH_NGHIEP_TICH_CUC_SET_LIST_DATA,
        DMChiTieuDoanhNghiepTichCucList: DMChiTieuDoanhNghiepTichCucList
    };
}

export function setMeta(meta) {
    return {
        type: DM_CHI_TIEU_DOANH_NGHIEP_TICH_CUC_SET_LIST_META,
        meta: meta
    };
}