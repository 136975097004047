
import * as Constants from './tkbaocaotkviphamConstant';

export function setData(tkbaocaotkviphamList) {
    return {
        type: Constants.TKBAOCAOTKVIPHAM_SET_LIST_DATA,
        tkbaocaotkviphamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TKBAOCAOTKVIPHAM_SET_LIST_META,
        meta
    };
}