
import * as Constants from './dmduongphoConstant';

export function setData(dmduongphoList) {
    return {
        type: Constants.DMDUONGPHO_SET_LIST_DATA,
        dmduongphoList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMDUONGPHO_SET_LIST_META,
        meta
    };
}