
import * as Constants from './phieukhaosatConstant';

export function setData(phieukhaosatList) {
    return {
        type: Constants.PHIEUKHAOSAT_SET_LIST_DATA,
        phieukhaosatList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.PHIEUKHAOSAT_SET_LIST_META,
        meta
    };
}