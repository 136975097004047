
import * as Constants from './chtraloiConstant';

export function setData(chtraloiList) {
    return {
        type: Constants.CHTRALOI_SET_LIST_DATA,
        chtraloiList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.CHTRALOI_SET_LIST_META,
        meta
    };
}