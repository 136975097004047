import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BehaviorsContext } from 'shared/services';
import { Context } from 'modules/tktaikhoan/TKTaiKhoanService';
import { tktaikhoanService } from './TKTaiKhoanService';
import { TKTaiKhoanListComponent } from './tktaikhoan-list/TKTaiKhoanListComponent';
import { TKTaiKhoanFormComponent } from './tktaikhoan-form/TKTaiKhoanFormComponent';
import { UserFormChangePasswordComponent } from './tktaikhoan-form/UserFormChangePasswordComponent';
class TKTaiKhoanModule extends Component {
    static propTypes={
        match:PropTypes.object
    }
    render() {
        let {path}=this.props.match;
        return (
            <BehaviorsContext.Consumer>
                {
                    ({beh})=>(
                        <Context.Provider value={{
                            tktaikhoanService: tktaikhoanService,
                            beh:beh
                        }} >
                         
                            <Switch>
                                <Route path={`${path}/view`} render={(props)=><TKTaiKhoanListComponent {...props} ></TKTaiKhoanListComponent>} ></Route>
                                <Route path={`${path}/:type`} render={(props)=><TKTaiKhoanListComponent {...props} ></TKTaiKhoanListComponent>} ></Route>
                                <Route path={`${path}/form/:id`} render={(props) => <TKTaiKhoanFormComponent {...props} />} />
                                <Route path={path} render={(props) => <TKTaiKhoanListComponent {...props} />} />
                                <Route path={path} render={(props) => <UserFormChangePasswordComponent {...props} />} />
                            </Switch>   
                        </Context.Provider>
                    )
                }
            </BehaviorsContext.Consumer>
        );
    }
}
export { TKTaiKhoanModule };