import * as actions from './sanphamdnConstants';

export function setData(sanphamdnList) {
    return {
        type: actions.SANPHAMDN_SET_LIST_DATA,
        sanphamdnList
    };
}

export function setMeta(meta) {
    return {
        type: actions.SANPHAMDN_SET_LIST_META,
        meta
    };
}