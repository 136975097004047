import * as Constant from './ththuonghieuConstant';

const ththuonghieu = {
    ththuonghieuList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: ''        
    }
};
const ththuonghieuReducer = (state = ththuonghieu, action) => {
    switch (action.type) {
        case Constant.THTHUONGHIEU_SET_LIST_DATA:
            return Object.assign({}, state, { ththuonghieuList: action.ththuonghieuList });
        case Constant.THTHUONGHIEU_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { ththuonghieuReducer};