
import * as Constants from './menuFrontEndConstant';

export function setData(menuFrontEndList) {
    return {
        type: Constants.MENUFRONTEND_SET_LIST_DATA,
        menuFrontEndList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.MENUFRONTEND_SET_LIST_META,
        meta
    };
}