
import * as Constants from './tochuccanhanviphamConstant';

export function setData(tochuccanhanviphamList) {
    return {
        type: Constants.TOCHUCCANHANVIPHAM_SET_LIST_DATA,
        tochuccanhanviphamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TOCHUCCANHANVIPHAM_SET_LIST_META,
        meta
    };
}