
import * as Constants from './menuhethongConstant';

export function setData(menuhethongList) {
    return {
        type: Constants.MENUHETHONG_SET_LIST_DATA,
        menuhethongList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.MENUHETHONG_SET_LIST_META,
        meta
    };
}