
import * as Constants from './dmchitieudanhgiaConstant';

export function setData(dmchitieudanhgiaList) {
    return {
        type: Constants.DMCHITIEUDANHGIA_SET_LIST_DATA,
        dmchitieudanhgiaList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHITIEUDANHGIA_SET_LIST_META,
        meta
    };
}