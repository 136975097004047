
import * as Constants from './cauhinhdulieulienketConstant';

export function setData(cauhinhdulieulienketList) {
    return {
        type: Constants.CAUHINHDULIEULIENKET_SET_LIST_DATA,
        cauhinhdulieulienketList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.CAUHINHDULIEULIENKET_SET_LIST_META,
        meta
    };
}