
import * as Constants from './bcviphamsanphamConstant';

export function setData(bcviphamsanphamList) {
    return {
        type: Constants.BCVIPHAMSANPHAM_SET_LIST_DATA,
        bcviphamsanphamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.BCVIPHAMSANPHAM_SET_LIST_META,
        meta
    };
}