
import * as Constants from './dmdonvilienketConstant';

export function setData(dmdonvilienketList) {
    return {
        type: Constants.DMDONVILIENKET_SET_LIST_DATA,
        dmdonvilienketList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMDONVILIENKET_SET_LIST_META,
        meta
    };
}