import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { string, object, ref } from 'yup';
import { LoadingComponent, FormSelect } from 'shared/components';
import { Context } from '../TKTaiKhoanService';
const schema = object({
    title: string().trim().required('Bạn chưa nhập họ và tên').min(3, 'Bạn nhập tối thiểu 3 ký tự').max(100, 'Bạn nhập tối đa 100 ký tự').matches("^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$", "Họ tên không chưa kí tự đặc biệt"),
    tendangnhap: string().trim().required('Bạn chưa nhập tên đăng nhập').min(5, 'Bạn nhập tối thiểu 5 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự').matches("^[a-zA-Z0-9]+$", "Tên đăng nhập không chứa kí tự đặc biệt"),
    dienthoai: string().trim().required('Bạn chưa nhập số điện thoại').max(20, 'Bạn nhập tối đa 20 ký tự').min(6, 'Bạn nhập tối thiểu 6 ký tự').matches("^[0-9]+$", "Bạn chỉ nhập được số"),
    email: string().email('Email không đúng định dạng').nullable().min(3, 'Bạn nhập tối thiểu 3 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự'),
    lstgroup: string().trim().required('Bạn chưa chọn nhóm quyền'),
    matkhau: string().trim().required('Bạn phải nhập mật khẩu mới').min(6, 'Bạn nhập tối thiểu 6 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự').matches(/(?=^.{6,30}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Mật khẩu phải có ít nhất một chữ số, một chữ cái thường, một chữ cái in hoa, một ký tự đặc biệt'),
    nhaplaimatkhau: string().trim().required('Bạn chưa nhập lại mật khẩu').oneOf([ref('matkhau'), null], 'Mật khẩu nhập lại không đúng'),
});
const schemantd = object({
    title: string().trim().required('Bạn chưa nhập họ và tên').min(3, 'Bạn nhập tối thiểu 3 ký tự').max(100, 'Bạn nhập tối đa 100 ký tự').matches("^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$", "Họ tên không chưa kí tự đặc biệt"),
    tendangnhap: string().trim().required('Bạn chưa nhập tên đăng nhập').min(5, 'Bạn nhập tối thiểu 5 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự').matches("^[a-zA-Z0-9]+$", "Tên đăng nhập không chứa kí tự đặc biệt"),
    dienthoai: string().trim().required('Bạn chưa nhập số điện thoại').max(20, 'Bạn nhập tối đa 20 ký tự').min(6, 'Bạn nhập tối thiểu 6 ký tự').matches("^[0-9]+$", "Bạn chỉ nhập được số"),
    email: string().email('Email không đúng định dạng').nullable().min(3, 'Bạn nhập tối thiểu 3 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự'),
    matkhau: string().trim().required('Bạn phải nhập mật khẩu mới').min(6, 'Bạn nhập tối thiểu 6 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự').matches(/(?=^.{6,30}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Mật khẩu phải có ít nhất một chữ số, một chữ cái thường, một chữ cái in hoa, một ký tự đặc biệt'),
    nhaplaimatkhau: string().trim().required('Bạn chưa nhập lại mật khẩu').oneOf([ref('matkhau'), null], 'Mật khẩu nhập lại không đúng'),
});
const schemaedit = object({
    title: string().trim().required('Bạn chưa nhập họ và tên').min(3, 'Bạn nhập tối thiểu 3 ký tự').max(100, 'Bạn nhập tối đa 100 ký tự').matches("^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$", "Họ tên không chưa kí tự đặc biệt"),
    tendangnhap: string().trim().required('Bạn chưa nhập tên đăng nhập'),
    dienthoai: string().trim().required('Bạn chưa nhập số điện thoại').max(20, 'Bạn nhập tối đa 20 ký tự').min(6, 'Bạn nhập tối thiểu 6 ký tự').matches("^[0-9]+$", "Bạn chỉ nhập được số"),
    email: string().email('Email không đúng định dạng').nullable(),
    lstgroup: string().trim().required('Bạn chưa chọn nhóm quyền').nullable(),
});
const schemaeditntd = object({
    title: string().trim().required('Bạn chưa nhập họ và tên').min(3, 'Bạn nhập tối thiểu 3 ký tự').max(100, 'Bạn nhập tối đa 100 ký tự').matches("^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$", "Họ tên không chưa kí tự đặc biệt"),
    tendangnhap: string().trim().required('Bạn chưa nhập tên đăng nhập'),
    dienthoai: string().trim().required('Bạn chưa nhập số điện thoại').max(20, 'Bạn nhập tối đa 20 ký tự').min(6, 'Bạn nhập tối thiểu 6 ký tự').matches("^[0-9]+$", "Bạn chỉ nhập được số"),
    email: string().email('Email không đúng định dạng').nullable(),
    lstgroup: string().trim().required('Bạn chưa chọn nhóm quyền').nullable(),
});
const userDefault = {
    title: '',
    tendangnhap: '',
    dienthoai: '',
    lstgroup: [],
    iD_TINHTHANH: '',
    lstgroupuser: null,
    email: '',
    matkhau: '',
    nhaplaimatkhau: ''
};

class TKTaiKhoanFormComponent extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        type: PropTypes.string
    }
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            data: userDefault,
            editMode: false,
            loading: false,
            isShow: false,
            action: 'new',
            id: null,
            datNhomNguoiDung: [],
            dataTinhThanh: [],
        };
        this.subscriptions = {};
    }
    componentDidMount() {
        this.subscriptions['sendToForm'] = this.context.tktaikhoanService.sendToForm.subscribe(res => {
            switch (res.action) {
                case 'new':
                    this.setState({ editMode: true, isShow: res.isShow, action: res.action, id: null, data: userDefault });
                    break;
                case 'edit':
                    this.setState({ editMode: true, isShow: res.isShow, action: res.action, id: res.id, loading: true });
                    this.context.tktaikhoanService.getByIdUpdate(res.id).subscribe(res => {

                        this.setState({
                            data: res,
                            loading: false
                        });
                    });
                    break;
                case 'read':
                    this.setState({ editMode: false, isShow: res.isShow, action: res.action, id: res.id, loading: true });
                    this.context.tktaikhoanService.getByIdUpdate(res.id).subscribe(res => {
                        this.setState({
                            data: res,
                            loading: false
                        });
                    });
                    break;
                default:
                    this.setState({ editMode: true, isShow: res.isShow, action: 'new', id: null, data: userDefault });
                    break;
            }
        });
        this.context.tktaikhoanService.getAllNhomNguoidung().subscribe(res => {
            var NhomQuyen = res.filter(Nq => Nq.title != "Người dùng ẩn danh");

            this.setState({ datNhomNguoiDung: NhomQuyen });
        });
        this.context.tktaikhoanService.getDmTinhThanh().subscribe(res => {
            this.setState({ dataTinhThanh: res });
        });

    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    onSubmit(data) {
        debugger;
        let { type } = this.props;
        if (type === "ntd") {
            data.lstgroup = [2];
        }
        this.setState({
            loading: true
        });
        if (this.state.id) {
            this.subscriptions['update'] = this.context.tktaikhoanService.update(data, this.state.id).subscribe(() => {
                this.context.beh.toast('Cập nhật thành công');
                this.handleClose(true);
                this.setState({
                    loading: false
                });
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error);
                });

        } else {
            this.subscriptions['create'] = this.context.tktaikhoanService.create(data).subscribe(() => {
                this.context.beh.toast('Thêm mới thành công');
                this.handleClose(true);
                this.setState({
                    loading: false
                });
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error);
                });
        }
    }
    onEdit() {
        this.setState({
            editMode: true
        });
    }
    handleClose(isRefesh) {
        this.setState({ isShow: false, data: userDefault });
        if (this.props.onClose) {
            this.props.onClose(isRefesh);
        }

    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    computedTitle() {
        if (this.state.id) {
            if (this.state.editMode) {
                return 'Chỉnh sửa thông tin tài khoản';
            }
            return 'Chi tiết tài khoản';
        }
        return 'Tạo mới tài khoản';
    }
    viewGroupUser(arr) {
        let sgroup = '';
        if (arr && arr.length > 0) {
            for (var i = 0; i < arr.length; i++) {
                if (i == 0)
                    sgroup += arr[i].title;
                else
                    sgroup += ', ' + arr[i].title;
            }
        }
        return sgroup;
    }
    render() {
        let { type } = this.props;
        return (
            <Modal size="xl" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.handleClose.bind(this)}>
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <Formik
                    validationSchema={this.state.id ? type === "ntd" ? schemaeditntd : schemaedit : type === "ntd" ? schemantd : schema}
                    onSubmit={(values) => {
                        this.onSubmit(values);
                    }}
                    enableReinitialize={true}
                    initialValues={this.state.data}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                            <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress.bind(this)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.computedTitle()}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="title">
                                            <Form.Label>Họ và tên</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="title"
                                                        value={values.title || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.title && !!errors.title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.title}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.title}</p>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="tendangnhap">
                                            <Form.Label>Tên đăng nhập</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="tendangnhap"
                                                        readOnly={this.state.id !== null}
                                                        value={values.tendangnhap || ''}
                                                        onChange={(ev) => { handleChange(ev); handleBlur(ev); }}
                                                        isInvalid={touched.tendangnhap && !!errors.tendangnhap}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.tendangnhap}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.tendangnhap}</p>
                                            }
                                        </Form.Group>
                                    </Form.Row>
                                    {this.state.id === null ?
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="matkhau">
                                                <Form.Label>Mật khẩu mới</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                                <Form.Control
                                                    type="password"
                                                    name="matkhau"
                                                    value={values.matkhau || ''}
                                                    onChange={(ev) => { handleChange(ev); handleBlur(ev); }}
                                                    isInvalid={touched.matkhau && !!errors.matkhau}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.matkhau}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="nhaplaimatkhau">
                                                <Form.Label>Nhập lại mật khẩu mới</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                                <Form.Control
                                                    type="password"
                                                    name="nhaplaimatkhau"
                                                    value={values.nhaplaimatkhau || ''}
                                                    onChange={handleChange}
                                                    isInvalid={touched.nhaplaimatkhau && !!errors.nhaplaimatkhau}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.nhaplaimatkhau}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row> : ''}
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="dienthoai">
                                            <Form.Label>Điện thoại</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="dienthoai"
                                                        value={values.dienthoai || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.dienthoai && !!errors.dienthoai}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.dienthoai}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.dienthoai}</p>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        value={values.email || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.email && !!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </React.Fragment>
                                                : <p className="form-control-static">{values.email}</p>
                                            }
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        {
                                            type !== "ntd" ? <Form.Group as={Col} md="6" controlId="lstgroup">
                                                <Form.Label>Nhóm quyền</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                                {this.state.editMode ?
                                                    <React.Fragment>
                                                        <FormSelect
                                                            placeholder="Chọn nhóm quyền"
                                                            name="lstgroup"
                                                            data={this.state.datNhomNguoiDung}
                                                            value={values.lstgroup}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={touched.lstgroup && !!errors.lstgroup}
                                                            mode="multiSelect"
                                                        ></FormSelect>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.lstgroup}
                                                        </Form.Control.Feedback>
                                                    </React.Fragment> :
                                                    <p className="form-control-static">{this.viewGroupUser(values.lstgroupuser)}</p>
                                                }
                                            </Form.Group> : ''
                                        }

                                        <Form.Group as={Col} md="6" controlId="iD_TINHTHANH">
                                            <Form.Label>Tỉnh thành</Form.Label>
                                            {this.state.editMode ?
                                                <React.Fragment>
                                                    <FormSelect
                                                        name="iD_TINHTHANH"
                                                        data={this.state.dataTinhThanh}
                                                        value={values.iD_TINHTHANH}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.iD_TINHTHANH && !!errors.iD_TINHTHANH}
                                                        mode="simpleSelect"
                                                    ></FormSelect>

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.iD_TINHTHANH}
                                                    </Form.Control.Feedback>
                                                </React.Fragment> :
                                                <p className="form-control-static">{values.titlE_TINHTHANH}</p>
                                            }

                                        </Form.Group>
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>

                                    {this.state.editMode ?
                                        <Button type="submit">Lưu lại</Button>
                                        : <React.Fragment>
                                            <Button variant="primary" className="ml-2" type="button" onClick={this.onEdit.bind(this)}>Sửa</Button>
                                        </React.Fragment>
                                    }
                                    <Button variant="default" onClick={() => { this.handleClose(false); }}>
                                        Đóng
                                </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                </Formik>
            </Modal>

        );
    }
}
export { TKTaiKhoanFormComponent };