
import * as Constants from './dmphuongxaConstant';

export function setData(dmphuongxaList) {
    return {
        type: Constants.DMPHUONGXA_SET_LIST_DATA,
        dmphuongxaList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMPHUONGXA_SET_LIST_META,
        meta
    };
}