
import * as Constants from './dmloaivanbanphapluatConstant';

export function setData(dmloaivanbanphapluatList) {
    return {
        type: Constants.DMLOAIVANBANPHAPLUAT_SET_LIST_DATA,
        dmloaivanbanphapluatList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMLOAIVANBANPHAPLUAT_SET_LIST_META,
        meta
    };
}