
import * as Constants from './dmdoituongquanlyConstant';

export function setData(dmdoituongquanlyList) {
    return {
        type: Constants.DMDOITUONGQUANLY_SET_LIST_DATA,
        dmdoituongquanlyList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMDOITUONGQUANLY_SET_LIST_META,
        meta
    };
}