
import * as Constants from './chcauhoiConstant';

export function setData(chcauhoiList) {
    return {
        type: Constants.CHCAUHOI_SET_LIST_DATA,
        chcauhoiList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.CHCAUHOI_SET_LIST_META,
        meta
    };
}