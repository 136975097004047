import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Image, Dropdown, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { authService } from 'shared/services';
import UserImg from 'assets/images/user2-160x160.jpg';
import Background from 'assets/images/Asset_3.png'
import logoNavBar from 'assets/images/logo-new.png'
import { TaiKhoanFormComponent, UserFormChangePasswordComponent, Context } from 'modules/tktaikhoan';
//import { Context } from 'modules/tktaikhoan/TKTaiKhoanService';
//import { UserFormChangePasswordComponent } from 'modules/tktaikhoan/tktaikhoan-form/UserFormChangePasswordComponent';
class NavBar extends React.Component {

    //getType() {
    //    switch (this.props.type) {
    //    case 'primary':
    //        return 'navbar-dark navbar-primary';
    //    case 'white':
    //        return 'navbar-light navbar-white';
    //    default:
    //        return 'navbar-light navbar-white';
    //    }

    //}
    logout() {
        authService.logout();
    }

    static contextType = Context;
    handleClose(res) {
        if (res) {
            this.setState({ loading: false });
        }
    }
    viewDetail() {
        this.context.tktaikhoanService.sendToFormAction.next({
            username: this.props.user.username,
            action: 'read',
            isShow: true
        });

    }
    changePassword() {
        this.context.tktaikhoanService.sendToFormChangePassword.next({
            id: this.props.user.id,
            action: 'changePass',
            isShow: true
        });

    }
    viewEdit() {
        this.context.tktaikhoanService.sendToFormAction.next({
            username: this.props.user.username,
            action: 'edit',
            isShow: true
        });
    }

    render() {
        return (
            //<nav className={'main-header navbar navbar-expand' + this.getType()}>


            <nav className={'main-header navbar navbar-expand navbar-dark navbar-info'} style={{
                marginLeft: 0, backgroundImage: `url(${Background})`, height: 90, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", paddingLeft: 0, paddingRight: 0, borderTop: "5px solid #ED3237"
            }}>
                <ul className="navbar-nav" style={{flex: 1}}>
                    <li className="nav-item d-sm-none d-block">
                        <div>
                            {/* eslint-disable-next-line*/}
                            <a className="nav-link" type="button" data-widget="pushmenu" onClick={this.props.onToggle}><i className="fa fa-bars"></i></a>
                        </div>


                    </li>
                    <li className="nav-item pt-1 d-flex align-items-center w-100">
                        <div className="d-flex flex-column justify-content-center align-items-center nav-center" style={{ minWidth: 220 }}>
                            <Image src={logoNavBar} />
                        </div>
                        <div className="w-100 titleNavbar">
                            <h5 className="text-white text-center text-uppercase">phần mềm quản lý về hàng giả, hàng nhái, buôn lậu, gian lận thương mại</h5>
                            <h5 className="text-white text-center text-uppercase">và vi phạm sở hữu trí tuệ</h5>
                        </div>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <Dropdown as="li" className="nav-item user-menu">
                        {/* eslint-disable-next-line*/}
                        <Dropdown.Toggle as="a" className="nav-link" href="#">
                            <Image src={UserImg} className="user-image img-circle elevation-2" alt="User Image" />
                            <span className="d-none d-md-inline">{this.props.user.fullName}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as="ul" className="dropdown-menu-sm" alignRight={true}>

                            <button className="dropdown-item" onClick={() => { this.viewDetail(); }} >
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                                      Xem thông tin
                                    </button>
                            <button className="dropdown-item" onClick={() => { this.viewEdit(); }} >
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                                      Sửa thông tin
                                    </button>

                            <button className="dropdown-item" onClick={() => { this.changePassword(); }}>
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                                      Đổi mật khẩu
                                    </button>

                            <div className="dropdown-divider" />
                            <a className="dropdown-item" onClick={this.logout} href="#" data-toggle="modal" data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                      Đăng xuất
                                    </a>

                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
                <TaiKhoanFormComponent onClose={this.handleClose.bind(this)} />
                <UserFormChangePasswordComponent />
            </nav>
           
                    
        );
    }
}
NavBar.propTypes = {
    onToggle: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.object
};
const mapStateToProps = (state) => {
    return {
        user: state.oauth
    };
};
const NavBarComponent = connect(mapStateToProps, {})(NavBar);
export { NavBarComponent };