import { loadable } from 'shared/utils';
const routes = [
    {
        path: '/home',
        component: loadable(() => import('../modules/home'))
    },

    {
        path: '/about',
        component: loadable(() => import('../modules/about'))
    },
    {
        path: '/dn-thuonghieu',
        component: loadable(() => import('../modules/dnthuonghieu'))
    },
    {
        path: '/dm-ctspqt',
        component: loadable(() => import('../modules/dmchitieusanphamquantam'))
    },
    {
        path: '/dm-ctsp',
        component: loadable(() => import('../modules/dmchitieusanpham'))
    },
    {
        path: '/menuhethong',
        component: loadable(() => import('../modules/menuhethong'))
    },
    {
        path: '/menu',
        component: loadable(() => import('../modules/menuFrontEnd'))
    },
    {
        path: '/user',
        component: loadable(() => import('../modules/user'))
    },
    {
        path: '/tk-taikhoan',
        component: loadable(() => import('../modules/tktaikhoan'))
    },
    {
        path: '/tk-tkpermission',
        component: loadable(() => import('../modules/tkpermission'))
    },
    {
        path: '/logsys',
        component: loadable(() => import('../modules/logSys'))
    },
    {
        path: '/user-modal',
        component: loadable(() => import('../modules/user-modal'))
    },
    {
        path: '/dm-chuyenmuctin',
        component: loadable(() => import('../modules/dmchuyenmuctin'))
    },
    {
        path: '/dm-doituongchiase',
        component: loadable(() => import('../modules/dmdoituongchiase'))
    },
    {
        path: '/dm-chudecauhoi',
        component: loadable(() => import('../modules/dmchudecauhoi'))
    },
    {
        path: '/dm-loaisanpham',
        component: loadable(() => import('../modules/dmloaisanpham'))
    },
    {
        path: '/dm-linhvuchoatdong',
        component: loadable(() => import('../modules/dmlinhvuckinhdoanh'))
    },
    {
        path: '/dm-tinhthanh',
        component: loadable(() => import('../modules/dmtinhthanh'))
    },
    {
        path: '/dm-diaban',
        component: loadable(() => import('../modules/dmdiaban'))
    },
    {
        path: '/dm-phuongxa',
        component: loadable(() => import('../modules/dmphuongxa'))
    },
    {
        path: '/dm-duongpho',
        component: loadable(() => import('../modules/dmduongpho'))
    },
    {
        path: '/dm-chitieu',
        component: loadable(() => import('../modules/dm-chitieu'))
    },
    {
        path: '/dm-loaithongbao',
        component: loadable(() => import('../modules/dmloaithongbao'))
    },
    {
        path: '/dm-quanhuyen',
        component: loadable(() => import('../modules/dmquanhuyen'))
    },
    {
        path: '/dm-loaihinhkinhdoanh',
        component: loadable(() => import('../modules/dmloaihinhkinhdoanh'))
    },
    {
        path: '/dm-donvilienket',
        component: loadable(() => import('../modules/dmdonvilienket'))
    },
    {
        path: '/dm-chitieudoanhnghieptichcuc',
        component: loadable(() => import('../modules/dmchitieudoanhnghieptichcuc'))
    },
    {
        path: '/dm-chitieunguoidungtichcuc',
        component: loadable(() => import('../modules/dmchitieunguoidungtichcuc'))
    },
    {
        path: '/dm-khoanggia',
        component: loadable(() => import('../modules/dmkhoanggia'))
    },
    {
        path: '/dm-donvi',
        component: loadable(() => import('../modules/dmdonvi'))
    },
    {
        path: '/dm-doituongquanly',
        component: loadable(() => import('../modules/dmdoituongquanly'))
    },
    {
        path: '/demo-control',
        component: loadable(() => import('../modules/demo-control'))
    },
    {
        path: '/modal',
        component: loadable(() => import('../modules/demo/ExampleModal'))
    },
    {
        path: '/validate-form',
        component: loadable(() => import('../modules/demo/ExampleValidateForm'))
    },
    {
        path: '/dm-chucvu',
        component: loadable(() => import('../modules/dmchucvu'))
    },
    {
        path: '/dm-chitieudanhgia',
        component: loadable(() => import('../modules/dmchitieudanhgia'))
    },
    {
        path: '/dm-hanhvivipham',
        component: loadable(() => import('../modules/dmhanhvivipham'))
    },
    {
        path: '/dm-coquanbanhanh',
        component: loadable(() => import('../modules/dmcoquanbanhanh'))
    },
    {
        path: '/dm-loaivanbanphapluat',
        component: loadable(() => import('../modules/dmloaivanbanphapluat'))
    },
    {
        path: '/dm-doituongquanly',
        component: loadable(() => import('../modules/dmdoituongquanly'))
    },
    {
        path: '/tk-nhomnguoidung',
        component: loadable(() => import('../modules/tknhomnguoidung'))
    },
    {
        path: '/tk-nhomnguoidungcn',
        component: loadable(() => import('../modules/tknhomnguoidungcn'))
    },
    {
        path: '/dm-linhvucsukien',
        component: loadable(() => import('../modules/dmlinhvucsukien'))
    },
    {
        path: '/hosovuviec',
        component: loadable(() => import('../modules/quanlynoidung/hosovuviec'))
    },
    {
        path: '/khotulieu',
        component: loadable(() => import('../modules/quanlynoidung/khotulieu'))
    },
    {
        path: '/bclydobaocao',
        component: loadable(() => import('../modules/quanlynoidung/bclydobaocao'))
    },
    {
        path: '/bcviphamsanpham',
        component: loadable(() => import('../modules/quanlynoidung/bcviphamsanpham'))
    },
    {
        path: '/qlndtindang',
        component: loadable(() => import('../modules/quanlynoidung/tindang'))
    },
    {
        path: '/tochuccanhanvipham',
        component: loadable(() => import('../modules/quanlynoidung/tochuccanhanvipham'))
    },
    {
        path: '/danhgiavehethong',
        component: loadable(() => import('../modules/danhgiavehethong'))
    },

    {
        path: '/thongketongquan',
        component: loadable(() => import('../modules/thongkebaocao/thongketongquan'))
    },
    {
        path: '/phieukhaosat',
        component: loadable(() => import('../modules/phieukhaosat'))
    },
    {
        path: '/cauhoikhaosat',
        component: loadable(() => import('../modules/cauhoikhaosat'))
    },
    {
        path: '/qlndbaidang',
        component: loadable(() => import('../modules/quanlynoidung/baidang'))
    },

    {
        path: '/ketquaphieukhaosat',
        component: loadable(() => import('../modules/ketquaphieukhaosat'))
    },
    {
        path: '/thongkeketquakhaosat',
        component: loadable(() => import('../modules/thongkeketquakhaosat'))
    },
    {
        path: '/cauhinhhethong',
        component: loadable(() => import('../modules/cauhinhhethong'))
    }, {
        path: '/cauhinhdulieulienket',
        component: loadable(() => import('../modules/cauhinhdulieulienket'))
    },
    {
        path: '/taikhoan/doanhnghiep',
        component: loadable(() => import('../modules/tktaikhoan/tkdoanhnghiep'))
    },
    {
        path: '/taikhoan/doanhnghiep/bcvp',
        component: loadable(() => import('../modules/tktaikhoan/tkdoanhnghiep'))
    },
    {
        path: '/qlsanpham',
        component: loadable(() => import('../modules/quanlynoidung/sanphamdn'))
    },
    {
        path: '/cauhinhemail',
        component: loadable(() => import('../modules/cauhinhemail'))
    },
    {
        path: '/tinbaotogiac',
        component: loadable(() => import('../modules/quanlynoidung/tinbaotogiac'))
    },
    {
        path: '/qlsukien',
        component: loadable(() => import('../modules/quanlynoidung/dnsukien'))
    },
    {
        path: '/bvtienich',
        component: loadable(() => import('../modules/bvtienich'))
    },
    {
        path: '/dieukhoanchinhsach',
        component: loadable(() => import('../modules/dieukhoanchinhsach'))
    },
    {
        path: '/chcauhoi',
        component: loadable(() => import('../modules/chcauhoi'))
    },
    {
        path: '/bannerquangcao',
        component: loadable(() => import('../modules/bannerquangcao'))
    },
    {
        path: '/chcauhoithuonggap',
        component: loadable(() => import('../modules/chcauhoithuonggap'))
    },
    {
        path: '/nhandienthuonghieu',
        component: loadable(() => import('../modules/quanlynoidung/ththuonghieu'))
    },
    {
        path: '/video',
        component: loadable(() => import('../modules/video'))
    },
    {
        path: '/phananh',
        component: loadable(() => import('../modules/quanlynoidung/phananh'))
    },
    {
        path: '/dmlinhvucthuonghieu',
        component: loadable(() => import('../modules/dmlinhvucthuonghieu'))
    },
    {
        path: '/thongkehoatdongdoanhnghiep',
        component: loadable(() => import('../modules/thongkebaocao/thongkehoatdongdoanhnghiep'))
    },
    {
        path: '/thongkehoatdongnguoitieudung',
        component: loadable(() => import('../modules/thongkebaocao/thongkehoatdongnguoitieudung'))
    },
    {
        path: '/thongkehoatdongqltt',
        component: loadable(() => import('../modules/thongkebaocao/thongkehoatdongqltt'))
    },
    {
        path: '/thongkequetmasanpham',
        component: loadable(() => import('../modules/thongkebaocao/thongkequetmasanpham'))
    },
    {
        path: '/thongketimkiem',
        component: loadable(() => import('../modules/thongkebaocao/thongketimkiemtrenhethong'))
    },
    {
        path: '/dmtukhoatieucuc',
        component: loadable(() => import('../modules/dmtukhoatieucuc'))
    },
   
    {
        path: '/vanbanphapluat',
        component: loadable(() => import('../modules/quanlynoidung/vanbanphapluat'))
    },
    {
        path: '/templateimport',
        component: loadable(() => import('../modules/templateimport'))
    },
];
export { routes };