
import * as Constants from './dmquanhuyenConstant';

export function setData(dmquanhuyenList) {
    return {
        type: Constants.DMQUANHUYEN_SET_LIST_DATA,
        dmquanhuyenList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMQUANHUYEN_SET_LIST_META,
        meta
    };
}