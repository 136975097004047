
import * as actions from './tkdoanhnghiepConstant';

export function setData(tkdoanhnghiepList) {
    return {
        type: actions.TKDOANHNGHIEP_SET_LIST_DATA,
        tkdoanhnghiepList
    };
}

export function setMeta(meta) {
    return {
        type: actions.TKDOANHNGHIEP_SET_LIST_META,
        meta
    };
}