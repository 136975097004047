
import * as Constants from './khotulieuConstant';

export function setData(khotulieuList) {
    return {
        type: Constants.KHOTULIEU_SET_LIST_DATA,
        khotulieuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.KHOTULIEU_SET_LIST_META,
        meta
    };
}