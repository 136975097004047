import * as Constant from './thnhanhieuConstant';

const thnhanhieu = {
    thnhanhieuList: [],
    meta: {
        page: 1,
        page_size: 20,
        sort: {id:'asc'},
        search: ''        
    }
};
const thnhanhieuReducer = (state = thnhanhieu, action) => {
    switch (action.type) {
        case Constant.THNHANHIEU_SET_LIST_DATA:
            return Object.assign({}, state, { thnhanhieuList: action.thnhanhieuList });
        case Constant.THNHANHIEU_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { thnhanhieuReducer};