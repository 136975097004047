import * as actions from './bvbaocaoviphamConstants';

export function setData(bvbaocaoviphamList) {
    return {
        type: actions.BVBAOCAOVIPHAM_SET_LIST_DATA,
        bvbaocaoviphamList
    };
}

export function setMeta(meta) {
    return {
        type: actions.BVBAOCAOVIPHAM_SET_LIST_META,
        meta
    };
}