import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'shared/components';
import { BehaviorsContext } from 'shared/services';
import { routes } from './MainRoutes';

import Wrapper from './Wrapper';
import { NavBarComponent } from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';
import '@iconify/iconify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../src/styles/styles.scss';
import { Route } from 'react-router-dom';
import { HomeComponent } from '../modules/home';
import { tktaikhoanService, Context } from 'modules/tktaikhoan';
//export default function AuthLayout() {
class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        };
        this.handlerClickToggle = this.handlerClickToggle.bind(this);
    }
    handlerClickToggle() {
        this.setState({
            toggle: !this.state.toggle
        });
    }
    render() {
        return (
            <BehaviorsContext.Consumer>
                {
                    ({ beh }) => (
                        <Context.Provider value={{
                            tktaikhoanService: tktaikhoanService,
                            beh: beh
                        }} >
                            <Wrapper
                                navbar={<NavBarComponent/>}
                                sidebar={<SideBar />}
                                footer={<Footer></Footer>}
                            >
                               
                                <Switch>
                                    {routes.map((route, i) => (
                                        <RouteWithSubRoutes key={i} {...route} />
                                    ))}

                                </Switch>

                            </Wrapper>
                        </Context.Provider>
                    )
                }
            </BehaviorsContext.Consumer>
        );
    }
}
export { MainLayout };



