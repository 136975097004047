
import * as Constants from './dmchitieusanphamConstant'

export function setData(dmchitieusanphamList) {
    return {
        type: Constants.DMCHITIEUSANPHAM_SET_LIST_DATA,
        dmchitieusanphamList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHITIEUSANPHAM_SET_LIST_META,
        meta
    };
}