
import * as Constants from './dmchudecauhoiConstant';

export function setData(dmchudecauhoiList) {
    return {
        type: Constants.DMCHUDECAUHOI_SET_LIST_DATA,
        dmchudecauhoiList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHUDECAUHOI_SET_LIST_META,
        meta
    };
}