import * as OauthAction from './oauth/oauthAction';
import * as UserAction from './user/userAction';
import * as DMChuyenMucTinAction from './dmchuyenmuctin/dmchuyenmuctinAction';
import * as DMDoiTuongChiaSeAction from './dmdoituongchiase/dmdoituongchiaseAction';
import * as DMChuDeCauHoiAction from './dmchudecauhoi/dmchudecauhoiAction';
import * as DMLinhVucKinhDoanhAction from './dmlinhvuckinhdoanh/dmlinhvuckinhdoanhAction';
import * as DNThuongHieuAction from './dnthuonghieu/dnthuonghieuAction';
import * as DMLoaiThongBaoAction from './dmloaithongbao/DMLoaiThongBaoAction';
import * as DMChiTieuAction from './dmchitieu/dmchitieuAction';
import * as DMDiaBanAction from './dmdiaban/dmdiabanAction';
import * as DMQuanHuyenAction from './dmquanhuyen/dmquanhuyenAction';
import * as DMTinhThanhAction from './dmtinhthanh/dmtinhthanhAction';
import * as DMPhuongXaAction from './dmphuongxa/dmphuongxaAction';
import * as DMDuongPhoAction from './dmduongpho/dmduongphoAction';
import * as DMChiTieuSanPhamQuanTamAction from './dmchitieusanphamquantam/dmchitieusanphamquantamAction';
import * as DMChiTieuSanPhamAction from './dmchitieusanpham/dmchitieusanphamAction';
import * as DMChiTieuNguoiDungTichCucAction from './dmchitieunguoidungtichcuc/DMChiTieuNguoiDungTichCucAction';
import * as DMChiTieuDoanhNghiepTichCucAction from './dmchitieudoanhnghieptichcuc/DMChiTieuDoanhNghiepTichCucAction';
import * as TKTaiKhoanAction from './tktaikhoan/tktaikhoanAction';
import * as DMLoaiSanPhamAction from './dmloaisanpham/dmloaisanphamAction';
import * as TKPermissionAction from './tkpermission/tkpermissionAction';
import * as DMLoaiHinhKinhDoanhAction from './dmloaihinhkinhdoanh/dmloaihinhkinhdoanhAction';
import * as DMDonViAction from './dmdonvi/DMDonViAction';
import * as DMKhoangGiaAction from './dmkhoanggia/DMKhoangGiaAction'; 
import * as TKNhomNguoiDungAction from './tknhomnguoidung/tknhomnguoidungAction';
import * as DMDoiTuongQuanLyAction from './dmdoituongquanly/dmdoituongquanlyAction';
import * as TKNhomNguoiDungCNAction from './tknhomnguoidungcn/tknhomnguoidungcnAction';
import * as dmchucvuAction from './dmchucvu/dmchucvuAction';
import * as dmchitieudanhgiaAction from './dmchitieudanhgia/dmchitieudanhgiaAction';
import * as dmhanhviviphamAction from './dmhanhvivipham/dmhanhviviphamAction';
import * as DMDonViLienKetAction from './dmdonvilienket/dmdonvilienketAction';
import * as dmlinhvucsukienAction from './dmlinhvucsukien/dmlinhvucsukienAction';
import * as LogSysAction from './logSys/logSysAction';
import * as MenuHeThongAction from './menuhethong/menuhethongAction';
import * as HoSoVuViecAction from './quanlynoidung/hosovuviec/hosovuviecAction';
import * as KhoTuLieuAction from './quanlynoidung/khotulieu/khotulieuAction';
import * as TinDangAction from './quanlynoidung/tindang/tindangAction';
import * as BCLyDoBaoCaoAction from './quanlynoidung/bclydobaocao/bclydobaocaoAction';
import * as BCViPhamSanPhamAction from './quanlynoidung/bcviphamsanpham/bcviphamsanphamAction';
import * as ToChucCaNhanViPhamAction from './quanlynoidung/tochuccanhanvipham/tochuccanhanviphamAction';
import * as danhgiavehethongAction from './danhgiavehethong/danhgiavehethongAction';
import * as phieukhaosatAction from './phieukhaosat/phieukhaosatAction';
import * as cauhoikhaosatAction from './cauhoikhaosat/cauhoikhaosatAction';
import * as ketquaphieukhaosatAction from './ketquaphieukhaosat/ketquaphieukhaosatAction';
import * as CauHinhHeThongAction from './cauhinhhethong/cauhinhhethongAction';
import * as BaiDangAction from './quanlynoidung/baidang/baidangAction';
import * as BinhLuanAction from './quanlynoidung/baidang/binhluan/binhluanAction';
import * as BVBaoCaoViPhamAction from './quanlynoidung/baidang/bvbaocaovipham/bvbaocaoviphamAction';
import * as traloiphieukhaosatAction from './traloiphieukhaosat/traloiphieukhaosatAction';
import * as DMApiWebServiceAction from './dmapiwebservice/dmapiwebserviceAction';
import * as CauHinhDuLieuLienKetAction from './cauhinhdulieulienket/cauhinhdulieulienketAction';
import * as TKDoanhNghiepAction from './tktaikhoan/tkdoanhnghiep/tkdoanhnghiepAction';
import * as SanPhamDNAction from './quanlynoidung/sanphamdn/sanphamdnAction';
import * as TinBaoToGiacAction from './quanlynoidung/tinbaotogiac/tinbaotogiacAction';
import * as TaiKhoanAction from './quanlynoidung/tinbaotogiac/taikhoan/taikhoanAction';
import * as BinhLuanSPAction from './quanlynoidung/sanphamdn/binhluansp/binhluanspAction';
import * as DNSuKienAction from './quanlynoidung/dnsukien/dnsukienAction';
import * as menuFrontEndAction from './menuFrontEnd/menuFrontEndAction';
import * as bvtienichAction from './bvtienich/bvtienichAction';
import * as dieukhoanchinhsachAction from './dieukhoanchinhsach/dieukhoanchinhsachAction';
import * as chcauhoiAction from './chcauhoi/chcauhoiAction';
import * as bannerquangcaoAction from './bannerquangcao/bannerquangcaoAction';
import * as chtraloiAction from './chtraloi/chtraloiAction';
import * as tkbaocaotkviphamAction from './tkbaocaotkvipham/tkbaocaotkviphamAction';
import * as chcauhoithuonggapAction from './chcauhoithuonggap/chcauhoithuonggapAction';
import * as ththuonghieuAction from './quanlynoidung/ththuonghieu/ththuonghieuAction';
import * as videoAction from './video/videoAction';
import * as thnhanhieuAction from './thnhanhieu/thnhanhieuAction';
import * as thdauhieunhanbietAction from './thdauhieunhanbiet/thdauhieunhanbietAction';
import * as PhanAnhAction from './quanlynoidung/phananh/phananhAction';
import * as PHBinhLuanAction from './quanlynoidung/phananh/binhluan/binhluanAction';
import * as dmlinhvucthuonghieuAction from './dmlinhvucthuonghieu/dmlinhvucthuonghieuAction';
import * as dmtukhoatieucucAction from './dmtukhoatieucuc/dmtukhoatieucucAction';
import * as DMCoQuanBanHanhAction from './dmcoquanbanhanh/dmcoquanbanhanhAction';
import * as DMLoaiVanBanPhapLuatAction from './dmloaivanbanphapluat/dmloaivanbanphapluatAction';
import * as vanbanphapluatAction from './quanlynoidung/vanbanphapluat/vanbanphapluatAction';
import * as templateimportAction from './templateimport/templateimportAction';
export {
    OauthAction,
    UserAction,
    DMChuyenMucTinAction,
    DMDoiTuongChiaSeAction,
    DMChuDeCauHoiAction,
    DMLinhVucKinhDoanhAction,
    DNThuongHieuAction,
    DMLoaiThongBaoAction,
    DMChiTieuAction,
    DMDiaBanAction,
    DMQuanHuyenAction,
    DMTinhThanhAction,
    DMPhuongXaAction,
    DMDuongPhoAction,
    DMChiTieuSanPhamQuanTamAction,
    DMChiTieuSanPhamAction,
    DMChiTieuNguoiDungTichCucAction,
    DMChiTieuDoanhNghiepTichCucAction,
    DMLoaiSanPhamAction, 
    TKPermissionAction,
    TKTaiKhoanAction,
    DMLoaiHinhKinhDoanhAction,
    DMKhoangGiaAction,
    DMDonViAction,
    TKNhomNguoiDungAction,
    DMDoiTuongQuanLyAction,
    TKNhomNguoiDungCNAction,
    dmchucvuAction,
    dmchitieudanhgiaAction,
    dmhanhviviphamAction,
    DMDonViLienKetAction,
    dmlinhvucsukienAction,
    LogSysAction,
    MenuHeThongAction,
    HoSoVuViecAction,
    KhoTuLieuAction,
    TinDangAction,
    BCLyDoBaoCaoAction,
    BCViPhamSanPhamAction,
    ToChucCaNhanViPhamAction,
    danhgiavehethongAction,
    phieukhaosatAction,
    cauhoikhaosatAction,
    ketquaphieukhaosatAction,
    BaiDangAction,
    BinhLuanAction,
    BVBaoCaoViPhamAction,
    traloiphieukhaosatAction,
    CauHinhHeThongAction,
    DMApiWebServiceAction,
    CauHinhDuLieuLienKetAction,
    TKDoanhNghiepAction,
    SanPhamDNAction,
    TinBaoToGiacAction,
    TaiKhoanAction,
    BinhLuanSPAction,
    DNSuKienAction,
    menuFrontEndAction,
    bvtienichAction,
    dieukhoanchinhsachAction,
    chcauhoiAction,
    bannerquangcaoAction,
    chtraloiAction,
    tkbaocaotkviphamAction,
    chcauhoithuonggapAction,
    ththuonghieuAction,
    videoAction,
    thnhanhieuAction,
    thdauhieunhanbietAction,
    PhanAnhAction,
    PHBinhLuanAction,
    dmlinhvucthuonghieuAction,
    dmtukhoatieucucAction,
    DMCoQuanBanHanhAction,
    DMLoaiVanBanPhapLuatAction,
    vanbanphapluatAction,
    templateimportAction,
};