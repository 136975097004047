
import * as Constants from './ththuonghieuConstant';

export function setData(ththuonghieuList) {
    return {
        type: Constants.THTHUONGHIEU_SET_LIST_DATA,
        ththuonghieuList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.THTHUONGHIEU_SET_LIST_META,
        meta
    };
}