import * as Constant from './templateimportConstant';

const templateimport = {
    templateimportList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: ''        
    }
};
const templateimportReducer = (state = templateimport, action) => {
    switch (action.type) {
        case Constant.TEMPLATEIMPORT_SET_LIST_DATA:
            return Object.assign({}, state, { templateimportList: action.templateimportList });
        case Constant.TEMPLATEIMPORT_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { templateimportReducer};