import * as actions from './phananhConstants';

export function setData(phananhList) {
    return {
        type: actions.PHANANH_SET_LIST_DATA,
        phananhList
    };
}

export function setMeta(meta) {
    return {
        type: actions.PHANANH_SET_LIST_META,
        meta
    };
}