
import { DM_LOAI_THONG_BAO_SET_LIST_DATA, DM_LOAI_THONG_BAO_SET_LIST_META } from './DMLoaiThongBaoConstant';


export function setData(DMLoaiThongBaoList) {
    return {
        type: DM_LOAI_THONG_BAO_SET_LIST_DATA,
        DMLoaiThongBaoList: DMLoaiThongBaoList
    };
}

export function setMeta(meta) {
    return {
        type: DM_LOAI_THONG_BAO_SET_LIST_META,
        meta: meta
    };
}