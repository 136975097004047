
import * as Constants from './bannerquangcaoConstant';

export function setData(bannerquangcaoList) {
    return {
        type: Constants.BANNERQUANGCAO_SET_LIST_DATA,
        bannerquangcaoList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.BANNERQUANGCAO_SET_LIST_META,
        meta
    };
}