import * as actions from './dnsukienConstants';

export function setData(dnsukienList) {
    return {
        type: actions.DNSUKIEN_SET_LIST_DATA,
        dnsukienList
    };
}

export function setMeta(meta) {
    return {
        type: actions.DNSUKIEN_SET_LIST_META,
        meta
    };
}