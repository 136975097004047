
import * as Constants from './dmcoquanbanhanhConstant';

export function setData(dmcoquanbanhanhList) {
    return {
        type: Constants.DMCOQUANBANHANH_SET_LIST_DATA,
        dmcoquanbanhanhList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCOQUANBANHANH_SET_LIST_META,
        meta
    };
}