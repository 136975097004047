import React from 'react';
import { Page } from 'shared/components';
import { Row, Col } from 'react-bootstrap';
//import { signalR  } from '@aspnet/signalr';
class HomeComponent extends React.Component {
   
    render() {
        return (
            <Page>
                <Page.Header>
                    <Row className="mb-2">
                        <Col sm={6}>
                            <h1>Chào mừng đến với trang quản trị</h1>
                        </Col>
                        <Col sm={6}>
                        </Col>

                    </Row>
                </Page.Header>
                <Page.Content>

                </Page.Content >
            </Page >
        );
    }
}

export { HomeComponent };