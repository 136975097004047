
import * as Constants from './tknhomnguoidungcnConstant';

export function setData(tknhomnguoidungcnList) {
    return {
        type: Constants.TKNHOMNGUOIDUNGCN_SET_LIST_DATA,
        tknhomnguoidungcnList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TKNHOMNGUOIDUNGCN_SET_LIST_META,
        meta
    };
}