
import * as Constants from './dmtukhoatieucucConstant';

export function setData(dmtukhoatieucucList) {
    return {
        type: Constants.DMTUKHOATIEUCUC_SET_LIST_DATA,
        dmtukhoatieucucList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMTUKHOATIEUCUC_SET_LIST_META,
        meta
    };
}