
import * as Constants from './tinbaotogiacConstant';

export function setData(tinbaotogiacList) {
    return {
        type: Constants.TINBAOTOGIAC_SET_LIST_DATA,
        tinbaotogiacList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.TINBAOTOGIAC_SET_LIST_META,
        meta
    };
}