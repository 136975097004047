
import * as Constants from './dmchuyenmuctinConstant';

export function setData(dmchuyenmuctinList) {
    return {
        type: Constants.DMCHUYENMUCTIN_SET_LIST_DATA,
        dmchuyenmuctinList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.DMCHUYENMUCTIN_SET_LIST_META,
        meta
    };
}