
import * as Constants from './logSysConstant';

export function setData(logSysList) {
    return {
        type: Constants.LOGSYS_SET_LIST_DATA,
        logSysList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.LOGSYS_SET_LIST_META,
        meta
    };
}