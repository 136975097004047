
import * as Constants from './bvtienichConstant';

export function setData(bvtienichList) {
    return {
        type: Constants.BVTIENICH_SET_LIST_DATA,
        bvtienichList
    };
}

export function setMeta(meta) {
    return {
        type: Constants.BVTIENICH_SET_LIST_META,
        meta
    };
}